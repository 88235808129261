<template>
  <div
    class="w-full h-auto p-5 bg-white rounded-xl border border-slate-200 flex flex-col gap-4"
  >
    <div class="flex justify-between items-start gap-4 w-full">
      <div
        class="w-[75%] h-auto bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6"
      >
        <div class="self-stretch flex justify-between items-center">
          <div class="grow shrink basis-0 h-5 flex items-center">
            <div
              class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
            >
              Timesheet Analysis
            </div>
          </div>
          <div
            class="pl-3.5 pr-2.5 py-1.5 rounded-lg border border-slate-200 flex justify-center items-center gap-2"
          >
            <div
              class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
            >
              Weekly
            </div>
            <div class="w-5 h-5 relative"></div>
          </div>
        </div>
        <div id="chart">
          <apexchart
            type="area"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
      <div
        class="w-[25%] h-auto bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6"
      >
        <div class="self-stretch flex justify-between items-center mb-4">
          <div class="grow shrink basis-0 h-5 flex items-center">
            <div
              class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
            >
              Project Status
            </div>
          </div>
        </div>
        <div id="chart2">
          <apexchart
            type="donut"
            :options="chartDonutOptions"
            :series="seriesDonut"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
import { ref } from "vue";
import ApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: ApexCharts,
  },
  setup() {
    const series = ref([
      {
        name: "STOCK ABC",
        data: ["280K", "150K", "320K", "130K", "140K", "500K", "180K"],
      },
    ]);

    const chartOptions = ref({
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      // title: {
      //   text: "Fundamental Analysis of Stocks",
      //   align: "left",
      // },
      // subtitle: {
      //   text: "Price Movements",
      //   align: "left",
      // },
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      xaxis: {
        type: "category",
      },
      yaxis: {
        opposite: false,
      },
      // legend: {
      //   horizontalAlign: "left",
      // },
    });

    const seriesDonut = ref([40, 60]);
    const chartDonutOptions = ref({
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      grid: {
        padding: {
          bottom: -80,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    return {
      series,
      chartOptions,
      seriesDonut,
      chartDonutOptions,
    };
  },
};
</script>
  