import api from "../../api";

export default {
  async getActiveProjects({ commit }) {
    try {
      const response = await api.get("projects/get-projects");
      commit("setCurrentProjects", response);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
  async getTeammates({ commit }) {
    try {
      const response = await api.get("projects/get-teammates");
      commit("setTeammates", response);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
  async updateAllocatedTime(_, data) {
    try {
      await api.post("/projects/update-left-hours", data);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
  async addProject({ commit }, data) {
    try {
      const response = await api.post(
        "new-kambuchi/projects/add-project",
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding project",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addProject action:", error);
      throw error;
    }
  },
  async fetchProject({ commit }, { id_project, user_type, id_user }) {
    try {
      const response = await api.get("/new-kambuchi/projects/get-project", {
        id: id_project,
        user_type: user_type,
        id_user: id_user,
      });
      commit("setDetails", response.project);
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async addProjectResources({ commit }, data) {
    try {
      const response = await api.post("/new-kambuchi/projects/add-users", data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding resources",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addProjectResources action:", error);
      throw error;
    }
  },
};
