import api from "../../api";
export default {
  async addExpense({ commit }, data) {
    try {
      const response = await api.post("/new-kambuchi/expenses/add-expenses", data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding expenses",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addExpense action:", error);
      throw error;
    }
  },

  async addExpensesResources({ commit }, data) {
    try {
      const response = await api.post(
        "/new-kambuchi/expenses/add-users",
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding resources",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addExpensesResources action:", error);
      throw error;
    }
  },

  async fetchExpense(_, { id_expense }) {
    try {
      const response = await api.get("/new-kambuchi/expenses/get-expense", {
        id: id_expense,
      });
      if (response.ok) {
        return response.expense;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  
  async fetchResourcesInExpense(_, { id_expense }) {
    try {
      const response = await api.get("/new-kambuchi/users/in-expense", {
        id_expense,
      });
      if (response.ok) {
        console.log("la response de fetch resources for task", response);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async editExpense({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/expenses/edit-expense`, data);

      if (response.message) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while editing expense",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in editExpense action:", error);
      throw error;
    }
  },

  async updateExpenseStatus({ commit }, data) {
    try {
      const response = await api.put(
        `/new-kambuchi/expenses/change-status`,
        data
      );

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: response.message,
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in updateExpenseStatus action:", error);
      throw error;
    }
  },

  async deleteExpense({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/expenses/delete-expense`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting expense",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteExpense action:", error);
      throw error;
    }
  },
};
