import api from "../../api";
import help from "../../helpers";
export default {
  async saveTracker(context, ticket) {
    try {
      await api.post("t-t/save-tracker", { ticket, startTime: new Date() });
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },

  async updateTracker(context, statusTakewaway) {
    try {
      const { hours, minutes, seconds } = help.convertSeconds(
        context.getters.elapsedTimeOnlySeconds
      );
      if (statusTakewaway.take) {
        context.commit("setSelectedTakeawayStatus", statusTakewaway.take);
      }
      if (context.getters.elapsedTimeOnlySeconds > 0) {
        await api.put("t-t/update-tracker", {
          ticket: context.state.tracker.selectedTakeaway.ticket,
          elapsedHours: hours,
          elapsedMinutes: minutes,
          elapsedSeconds: seconds,
          statusTakewaway: statusTakewaway.take
            ? statusTakewaway.take
            : context.state.tracker.selectedTakeaway.status,
          lastUpdatedDate: new Date(),
          isPause: statusTakewaway.isPause ? statusTakewaway.isPause : null,
          comments: statusTakewaway.comments ? statusTakewaway.comments : null,
        });
      }

      if (statusTakewaway.cleanSeconds) {
        if (statusTakewaway.take == "Complete") {
          const decimalTime = help.convertSecondsToDecimalHours(
            context.getters.elapsedTimeOnlySeconds
          );
          await context.dispatch("updateAllocatedTime", {
            projectName: context.state.tracker.selectedTakeaway.project_id,
            decimalTime: decimalTime,
          });
        }

        context.commit("clearElapsedTimeOnlySeconds");
        await context.dispatch("saveActiveTimer", {
          uid: context.state.user.uid,
          isTimerActive: false,
          isTimerPaused: false,
          activeDate: null,
          pausedDate: null,
          ticket: null,
          stop: null,
        });
        context.commit("clearSelectedTakeaway");
      }
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },

  async newUpdateTracker(context, statusTakewaway) {
    try {
      const { hours, minutes, seconds } = help.convertSeconds(
        context.getters.elapsedTimeOnlySeconds
      );
      if (statusTakewaway.take) {
        context.commit("setSelectedTakeawayStatus", statusTakewaway.take);
      }
      if (context.getters.elapsedTimeOnlySeconds > 0) {
        const dataTracker = {
          id_task: context.state.tracker.selectedTakeaway.id_task,
          id_project: context.state.tracker.selectedTakeaway.id_project,
          id_user: context.state.user.uid,
          seconds_elapsed: context.getters.elapsedTimeOnlySeconds,
          elapsed_hours: hours,
          elapsed_minutes: minutes,
          elapsed_seconds: seconds,
          status: statusTakewaway.take
            ? statusTakewaway.take
            : context.state.tracker.selectedTakeaway.status,
        };
        //Esto es lo que mandaba el time del tracker a smartsheet
        // await api.put("/t-t/update-tracker", {
        //   ticket: context.state.tracker.selectedTakeaway.id_task,
        //   elapsedHours: hours,
        //   elapsedMinutes: minutes,
        //   elapsedSeconds: seconds,
        //   statusTakewaway: statusTakewaway.take
        //     ? statusTakewaway.take
        //     : context.state.tracker.selectedTakeaway.status,
        //   lastUpdatedDate: new Date(),
        //   isPause: statusTakewaway.isPause ? statusTakewaway.isPause : null,
        //   comments: statusTakewaway.comments ? statusTakewaway.comments : null,
        // });
        await context.dispatch("startTracker", dataTracker);
      }

      if (statusTakewaway.cleanSeconds) {
        if (statusTakewaway.take == "Complete") {
          const decimalTime = help.convertSecondsToDecimalHours(
            context.getters.elapsedTimeOnlySeconds
          );
          await context.dispatch("updateAllocatedTime", {
            projectName: context.getters.selectedTakeaway.id_project,
            decimalTime: decimalTime,
          });
        }

        context.commit("clearElapsedTimeOnlySeconds");
        await context.dispatch("saveActiveTimer", {
          uid: context.state.user.uid,
          isTimerActive: false,
          isTimerPaused: false,
          activeDate: null,
          pausedDate: null,
          ticket: null,
          stop: null,
        });
        context.commit("clearSelectedTakeaway");
      }
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },

  async updateTrackerEdit(context, statusTakewaway) {
    try {
      await api.put("t-t/update-tracker", {
        ticket: statusTakewaway.ticket,
        elapsedHours: statusTakewaway.elapsedHours,
        elapsedMinutes: statusTakewaway.elapsedMinutes,
        elapsedSeconds: statusTakewaway.elapsedSeconds,
        statusTakewaway: statusTakewaway.status,
        lastUpdatedDate: new Date(),
        comments: statusTakewaway.comments ? statusTakewaway.comments : null,
      });
      if (statusTakewaway.status == "Complete") {
        const decimalTime = help.convertToDecimalHours(
          statusTakewaway.elapsedHours,
          statusTakewaway.elapsedMinutes,
          statusTakewaway.elapsedSeconds
        );
        await context.dispatch("updateAllocatedTime", {
          projectName: statusTakewaway.projectName,
          decimalTime: decimalTime,
        });
      }
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },
  async setTracker({ commit }, data) {
    commit("setTrackerElapsedTime", data);
  },

  async clearElapsedTime({ commit }) {
    commit("clearElapsedTime");
  },
  async getTracker({ commit }, data) {
    const { ticket } = data;
    try {
      const response = await api.get("t-t/tracker", ticket);
      if (response.tracker.error != "No matching documents") {
        const trackerData = response.tracker.data;
        let elapsedHours = null;
        let elapsedMinutes = null;
        let elapsedSeconds = null;
        let ticket = null;

        Object.keys(trackerData).forEach((key) => {
          const data = trackerData[key];
          elapsedHours = data.elapsedHours;
          elapsedMinutes = data.elapsedMinutes;
          elapsedSeconds = data.elapsedSeconds;
          ticket = data.ticket;
        });

        const elapsedTime = {
          elapsedHours,
          elapsedMinutes,
          elapsedSeconds,
          ticket,
        };
        commit("setTrackerElapsedTime", elapsedTime);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
  setElapsedTimeOnlySeconds({ commit }, data) {
    commit("setElapsedTimeOnlySeconds", data);
  },
  async startTimer({ commit, dispatch, state, getters }, data) {
    commit("setIsTimerActive");
    if (data.startWithTime && data.startWithTime > 0) {
      state.worker.postMessage({
        type: "resume",
        totalSeconds: data.startWithTime,
      });
    } else {
      state.worker.postMessage({ type: "start" });
    }
    if (data.isFromActiveTimer != true) {
      if (!data?.firestoreFlag) {
        await dispatch("saveActiveTimer", {
          uid: state.user.uid,
          isTimerActive: true,
          isTimerPaused: false,
          activeDate: new Date(),
          pausedDate: null,
          ticket: getters.selectedTakeaway.id_task,
          stop: false,
          currentAction: "start",
        });
      }
    }
  },
  async pauseTimer({ state, dispatch, getters }, data) {
    state.worker.postMessage({ type: "pause" });
    if (data.fromButton == true) {
      // await dispatch("updateTracker", {
      //   isPause: true,
      // });
      await dispatch("newUpdateTracker", {});
    }

    if (!data?.firestoreFlag) {
      console.log("no soy de firestore, voy a actualizar firestor");
      await dispatch("saveActiveTimer", {
        uid: state.user.uid,
        isTimerActive: true,
        isTimerPaused: true,
        activeDate: new Date(),
        pausedDate: new Date(),
        stop: false,
        ticket: getters.selectedTakeaway.id_task,
        currentAction: "pause",
      });
    } else {
      console.log("si soy de firestore, no wa actualizar nada");
    }
  },
  async stopTimer({ dispatch, /*commit,*/ state }, data) {
    state.worker.postMessage({ type: "stop" });
    if (!data?.firestoreFlag) {
      await dispatch("saveActiveTimer", {
        uid: state.user.uid,
        isTimerActive: false,
        isTimerPaused: false,
        activeDate: null,
        pausedDate: null,
        ticket: null,
        stop: true,
        currentAction: "stop",
      });
    }
  },
  async updateElapsedTime({ state, commit }) {
    state.worker.onmessage = (e) => {
      if (e.data.type === "tick") {
        commit("setElapsedTimeOnlySeconds", e.data.elapsedTimeOnlySeconds);
      }
    };
  },
  setSelectedTakeaway({ commit }, data) {
    commit("setSelectedTakeaway", data);
  },
  async saveActiveTimer(_, data1) {
    try {
      const data = {
        uid: data1.uid,
        isTimerActive: data1.isTimerActive,
        isTimerPaused: data1.isTimerPaused,
        activeDate: data1.activeDate,
        pausedDate: data1.pausedDate,
        ticket: data1.ticket ? data1.ticket : null,
        stop: data1.stop,
        currentAction: data1.currentAction ? data1.currentAction : null,
      };
      await api.post("/users/save-timer-from-user", data);
    } catch (error) {
      console.error("Error in request:", error);
      throw error;
    }
  },

  async getActiveTimer({ dispatch, state, commit }, uid) {
    try {
      const response = await api.get("/users/get-timer-from-user", {
        uid: uid,
      });
      if (response.data?.isTimerActive || response.data?.isTimerPaused) {
        commit("setIsTimerActive");
        const responseTakeaway = await api.get("/takeaways/get-takeaway", {
          id_task: response.data.ticket,
        });

        const dataForTakeaway = {
          project_name: responseTakeaway.data.Project.name,
          task_description: responseTakeaway.data.description,
          id_task: responseTakeaway.data.id_task,
          id_project: responseTakeaway.data.Project.id_project,
        };
        await commit("setSelectedTakeaway", dataForTakeaway);
        const responseTracker = await api.get("/t-t/tracker", {
          id_task: response.data.ticket,
          id_user: state.user.uid,
        });
        const trackerData = responseTracker.data;

        if (trackerData.last_track_at) {
          if (response.data.isTimerPaused) {
            await commit(
              "setElapsedTimeOnlySeconds",
              parseInt(trackerData.seconds_elapsed)
            );
            await dispatch("pauseTimer", { firestoreFlag: true });
          } else {
            const differenceInMilliseconds =
              Date.now() - new Date(response.data.activeDate);
            const differenceInSeconds = Math.floor(
              differenceInMilliseconds / 1000
            );
            const dataStartTime = {
              ticket: response.data.ticket,
              startWithTime:
                differenceInSeconds + parseInt(trackerData.seconds_elapsed),
              isFromActiveTimer: true,
            };
            await commit("setElapsedTimeOnlySeconds", differenceInSeconds);
            await dispatch("startTimer", dataStartTime);
            await dispatch("updateElapsedTime");
          }
        } else {
          const differenceInMilliseconds =
            Date.now() - new Date(trackerData.last_track_at);
          const differenceInSeconds = Math.floor(
            differenceInMilliseconds / 1000
          );
          const dataStartTime = {
            ticket: response.data.ticket,
            startWithTime: differenceInSeconds,
            isFromActiveTimer: true,
          };
          await commit("setElapsedTimeOnlySeconds", differenceInSeconds);
          await dispatch("startTimer", dataStartTime);
          await dispatch("updateElapsedTime");
        }
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
};
