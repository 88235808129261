<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else
    class="w-full h-[370px] flex-col justify-start items-center gap-10 inline-flex"
  >
    <div class="w-full h-[232px] flex flex-col justify-start items-center">
      <div class="relative w-full flex justify-center items-center">
        <div
          class="w-full h-[155px] bg-blue-100 rounded-tl-lg rounded-tr-lg"
        ></div>
        <div
          class="absolute w-full h-40 px-8 flex flex-col justify-start items-start gap-6"
          style="top: 115px"
        >
          <div class="w-full flex justify-start items-start gap-6">
            <div
              class="w-40 h-40 rounded-full flex justify-center items-center"
            >
              <img
                class="w-40 h-40 relative rounded-full shadow border-4 border-white"
                :src="data.photo"
              />
            </div>
            <div
              class="flex-grow pt-16 flex flex-col justify-start items-start gap-5"
            >
              <div class="w-full flex justify-start items-start gap-4">
                <div
                  class="flex-grow flex flex-col justify-start items-start gap-[9px]"
                >
                  <div
                    class="text-gray-900 text-2xl font-bold font-['Public Sans'] leading-[34px]"
                  >
                    {{ data.name }} {{ data.last_name }}
                  </div>
                  <div class="flex justify-start items-center gap-1"></div>
                </div>
                <button
                  class="flex justify-start items-center gap-3"
                  @click="editClient()"
                >
                  <div
                    class="px-4 py-2.5 bg-slate-100 rounded-lg shadow flex justify-center items-center gap-2"
                  >
                    <img
                      src="/icons/client-management/edit-profile.svg"
                      class="w-5 h-5 relative"
                    />
                    <div
                      class="text-blue-700 text-sm font-semibold font-['Inter'] leading-tight"
                    >
                      Edit Profile
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div
      class="w-full pb-10 border-b border-slate-200 justify-between items-start inline-flex"
    >
      <div class="flex-col justify-center items-start gap-1 inline-flex">
        <div
          class="text-slate-500 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          Company Name
        </div>
        <div
          class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
        >
          {{ data.company_name }}
        </div>
      </div>
      <div class="flex-col justify-center items-start gap-1 inline-flex">
        <div
          class="text-slate-500 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          Phone Number
        </div>
        <div
          class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
        >
          {{ helpers.formatPhoneNumber(data.phone_number) }}
        </div>
      </div>
      <div class="flex-col justify-center items-start gap-1 inline-flex">
        <div
          class="text-slate-500 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          Email
        </div>
        <div
          class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
        >
          {{ data.email }}
        </div>
      </div>
      <div class="flex-col justify-center items-start gap-1 inline-flex">
        <div
          class="text-slate-500 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          Address
        </div>
        <div
          class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
        >
          {{ data.street }} {{ data.apartment_unit }} {{ data.city }},
          {{ data.state }} {{ data.zipcode }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import helpers from "../../helpers";
import LoaderSpinner from "../CircularLoader.vue";
export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const clientId = route.params.id;
    const store = useStore();
    const data = computed(() => store.getters.detailsData);
    const flagLoading = ref(false);

    onMounted(async () => {
      flagLoading.value = true;

      await store.dispatch("fetchClient", { id_client: clientId });
      flagLoading.value = false;
    });

    function editClient() {
      router.push({ name: "edit-client", params: { id: clientId } });
    }
    return {
      data,
      helpers,
      flagLoading,
      editClient,
    };
  },
};
</script>

