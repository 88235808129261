<template>
  <div class="relative" ref="menuContainer">
    <img
      src="/icons/projects/action-dots.svg"
      class="w-5 h-5 pointer-events-auto cursor-pointer"
      @click.stop="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10"
    >
      <ul>
        <li
          @click="handleAction('View')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          View timesheet detail
        </li>
       
        <!-- <li
          @click="handleAction('Change')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Change status
        </li> -->
        <li
          @click="handleAction('Delete')"
          class="px-4 py-2 cursor-pointer hover:bg-red-400 bg-red-200"
        >
          Delete timesheet
        </li>
      </ul>
    </div>
    <ModalViewTimesheet
      v-if="showModaTimesheetView"
      :id_timesheet="item.id_timesheet"
      :status="item.status"
      :isOpen="showModaTimesheetView"
      @close="showModaTimesheetView = false"
    />
    <ModalDelete
      v-if="showDeletedModal"
      :from="'timesheet'"
      :item="item"
      :isOpen="showDeletedModal"
      @close="showDeletedModal = false"
    />
  </div>
</template>
    
    <script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import menuState from "../../../const/taskMenuState";
import ModalViewTimesheet from "./TimesheetDetails.vue";
import ModalDelete from "../DeleteModal.vue";

export default {
  props: {
    item: Object,
  },
  components: {
    ModalViewTimesheet,
    ModalDelete,
  },
  setup(props) {
    const showMenu = ref(false);
    const showModaTimesheetView = ref(false);
    const menuContainer = ref(null);
    const showDeletedModal = ref(false);

    const toggleMenu = () => {
      const newMenuState = !showMenu.value;
      menuState.setOpenMenuId(newMenuState ? props.item.id_timesheet : null);
    };

    const handleAction = (action) => {
      console.log(`Action: ${action}, Item: ${props.item.id_timesheet}`);
      if (action == "View") {
        toggleModalView();
      } else if (action == "Delete") {
        deleteTimesheet();
      }
      showMenu.value = false;
      menuState.setOpenMenuId(null);
    };

    const handleClickOutside = (event) => {
      if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        showMenu.value = false;
        menuState.setOpenMenuId(null);
      }
    };

    watch(
      () => menuState.getOpenMenuId(),
      (newId) => {
        showMenu.value = newId === props.item.id_timesheet;
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleModalView() {
      showModaTimesheetView.value = !showModaTimesheetView.value;
    }
    function deleteTimesheet() {
      showDeletedModal.value = !showDeletedModal.value;
    }

    return {
      showMenu,
      toggleMenu,
      handleAction,
      menuContainer,
      showModaTimesheetView,
      showDeletedModal,
    };
  },
};
</script>
    
    <style scoped>
/* Add necessary styles if needed */
</style>
    