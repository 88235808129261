<template>
  <div
    div
    v-if="flagShow"
    class="w-full h-[82px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
  >
    <div
      class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
    >
      <button
        @click="selectType('Submitted')"
        :class="
          selectedType == 'Submitted'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Submitted'
              ? 'text-blue_primary text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Submitted
        </div>
      </button>
      <button
        @click="selectType('Approved')"
        :class="
          selectedType == 'Approved'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Approved'
              ? 'text-emerald-700 text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Approved
        </div>
      </button>
      <button
        @click="selectType('Rejected')"
        :class="
          selectedType == 'Rejected'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Rejected'
              ? 'text-red-700 text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Rejected
        </div>
      </button>
    </div>
    <div class="justify-start items-center gap-3 flex">
      <div class="relative">
        <input
          type="text"
          placeholder="Search expenses..."
          v-model="searchQuery"
          @input="fetchFiltered"
          class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          style="pointer-events: none"
        />
      </div>
      <button
        class="px-4 py-2.5 bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Inter'] leading-tight"
        >
          Filter
        </div>

        <img src="/icons/projects/filter.svg" class="w-5 h-5 relative" />
      </button>
      <button
        v-if="sidebar == false"
        @click="toggleModal()"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Expense
        </div>
      </button>
      <button
        v-else
        @click="newExpenseView()"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Expense
        </div>
      </button>
    </div>
    <CreateNewExpense
      v-if="showModalCreateExpense"
      :isOpen="showModalCreateExpense"
      :projectId="id_project"
      @close="showModalCreateExpense = false"
    />
  </div>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import CreateNewExpense from "../Expenses/ExpensesCreateNew.vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  components: {
    CreateNewExpense,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const id_project = route.params.id;
    const store = useStore();
    const searchQuery = ref("");
    const showModalCreateExpense = ref(false);
    const sidebar = ref(false);
    const flagShow = ref(false);

    const selectedType = computed(() => store.getters.selectedType);

    function toggleModal() {
      showModalCreateExpense.value = !showModalCreateExpense.value;
    }

    function newExpenseView() {
      router.push({ name: "create-new-expense" });
    }

    const selectType = async (type) => {
      await store.commit("setSelectedType", type);
      await store.dispatch("fetchTable", {
        page: 1,
        limit: 10,
        type: "expenses",
        searchType: type,
        id_project: id_project
      });

      emit("type-selected-task", type);
    };

    const fetchFiltered = helpers.createDebouncedFetch(
      store,
      "expenses",
      selectedType,
      id_project
    );

    onMounted(async () => {
      try {
        flagShow.value = false;
        if (selectedType.value == null) {
          await store.commit("setSelectedType", "Submitted");
        }
        flagShow.value = true;
      } catch (error) {
        console.error("Error setting type:", error);
      }
    });

    return {
      toggleModal,
      showModalCreateExpense,
      sidebar,
      newExpenseView,
      id_project,
      selectType,
      flagShow,
      selectedType,
      searchQuery,
      fetchFiltered   
    };
  },
};
</script>