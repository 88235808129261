<template>
  <div class="relative" ref="menuContainer">
    <img
      src="/icons/projects/action-dots.svg"
      class="w-5 h-5 pointer-events-auto cursor-pointer"
      @click.stop="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10"
    >
      <ul>
        <li
          @click="handleAction('View')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          View invoice detail
        </li>
        <!-- <li
          v-if="user_type == 1"
          @click="handleAction('Edit')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Edit invoice
        </li> -->
        <li
          @click="handleAction('Delete')"
          class="px-4 py-2 cursor-pointer hover:bg-red-400 bg-red-200"
        >
          Delete expense
        </li>
      </ul>
    </div>
    <ModalViewInvoice
      v-if="showModaInvoiceView"
      :id_invoice="item.id_invoice"
      :status="item.status"
      :isOpen="showModaInvoiceView"
      @close="showModaInvoiceView = false"
    />
    <ModalDelete
      v-if="showDeletedModal"
      :from="'invoice'"
      :item="item"
      :isOpen="showDeletedModal"
      @close="showDeletedModal = false"
    />
  </div>
</template>
    
    <script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import menuState from "../../../const/taskMenuState";
import ModalViewInvoice from "./InvoiceDetails.vue";
import ModalDelete from "../DeleteModal.vue"
// import { useStore } from "vuex";

export default {
  props: {
    item: Object,
  },
  components: {
    ModalViewInvoice,
    ModalDelete
  },
  setup(props) {
    const showMenu = ref(false);
    const showModaInvoiceView = ref(false);
    const menuContainer = ref(null);
    const showDeletedModal = ref(false);

    const toggleMenu = () => {
      const newMenuState = !showMenu.value;
      menuState.setOpenMenuId(newMenuState ? props.item.id_invoice : null);
    };

    const handleAction = (action) => {
      console.log(`Action: ${action}, Item: ${props.item.id_invoice}`);
      if (action == "View") {
        toggleModalView();
      } else if (action == "Delete") {
        deleteInvoice()
      }
      showMenu.value = false;
      menuState.setOpenMenuId(null);
    };

    const handleClickOutside = (event) => {
      if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        showMenu.value = false;
        menuState.setOpenMenuId(null);
      }
    };

    watch(
      () => menuState.getOpenMenuId(),
      (newId) => {
        showMenu.value = newId === props.item.id_invoice;
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleModalView() {
      showModaInvoiceView.value = !showModaInvoiceView.value;
    }
    function deleteInvoice() {
      showDeletedModal.value = !showDeletedModal.value;
    }
    return {
      showMenu,
      toggleMenu,
      handleAction,
      menuContainer,
      showModaInvoiceView,
      // user_type,
      showDeletedModal
    };
  },
};
</script>
    
    <style scoped>
/* Add necessary styles if needed */
</style>
    