<template>
  <div
    class="w-full h-auto px-6 pt-3 pb-4 border-t border-zinc-200 justify-between items-center inline-flex min-h-fit"
  >
    <div
      class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
    >
      Page {{ pagination.currentPage }} of {{ pagination.totalPages }}
    </div>
    <div class="justify-start items-start gap-3 flex">
      <button
        @click="previous()"
        class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
        >
          Previous
        </div>
      </button>
      <button
        @click="next()"
        class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
        >
          Next
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const currentPath = route.path;
    const routeId = route.params.id;
    const pagination = computed(() => store.getters.pagination);
    const selectedTab = computed(() => store.getters.selectedTab);
    const selectedType = computed(() => store.getters.selectedType);

    async function next() {
      if (currentPath.includes("client-management")) {
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "clients",
          });
        }
      } else if (currentPath.includes("resource-management")) {
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "users",
            searchType: selectedTab.value === "Employees" ? 2 : 3,
          });
        }
      } else if (currentPath.includes("client-desc")) {
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: selectedTab.value.toLowerCase(),
            id_client: routeId,
          });
        }
      } else if (currentPath.includes("projects")) {
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "projects",
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Tasks"
      ) {
        const id_project = route.params.id;
        const types =
          selectedType.value == "Active"
            ? ["In progress", "Not Started"]
            : ["Complete"];
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "tasks",
            searchType: types,
            id_project: id_project,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Expenses"
      ) {
        const id_project = route.params.id;
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "expenses",
            searchType: selectedType.value,
            id_project: id_project,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Invoices"
      ) {
        const id_project = route.params.id;
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "invoices",
            searchType: selectedType.value,
            id_project: id_project,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Timesheets"
      ) {
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "timesheets",
            searchType: selectedType.value,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Bills"
      ) {
        if (pagination.value.currentPage != pagination.value.totalPages) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage + 1,
            limit: 10,
            type: "bills",
            searchType: selectedType.value,
          });
        }
      }
    }

    async function previous() {
      if (currentPath.includes("client-management")) {
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "clients",
          });
        }
      } else if (currentPath.includes("client-desc")) {
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: selectedTab.value.toLowerCase(),
            id_client: routeId,
          });
        }
      } else if (currentPath.includes("resource-management")) {
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "users",
            searchType: selectedTab.value === "Employees" ? 2 : 3,
          });
        }
      } else if (currentPath.includes("projects")) {
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "projects",
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Tasks"
      ) {
        const id_project = route.params.id;
        const types =
          selectedType.value == "Active"
            ? ["In progress", "Not Started"]
            : ["Complete"];
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "tasks",
            searchType: types,
            id_project: id_project,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Expenses"
      ) {
        const id_project = route.params.id;
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "expenses",
            searchType: selectedType.value,
            id_project: id_project,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Invoices"
      ) {
        const id_project = route.params.id;
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "invoices",
            searchType: selectedType.value,
            id_project: id_project,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Timesheets"
      ) {
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "timesheets",
            searchType: selectedType.value,
          });
        }
      } else if (
        currentPath.includes("project-details") &&
        selectedTab.value == "Bills"
      ) {
        if (pagination.value.currentPage >= 2) {
          await store.dispatch("fetchTable", {
            page: pagination.value.currentPage - 1,
            limit: 10,
            type: "bills",
            searchType: selectedType.value,
          });
        }
      }
    }

    return {
      pagination,
      next,
      previous,
    };
  },
};
</script> 
