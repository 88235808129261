<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div class="w-[572px] bg-white rounded-xl shadow flex flex-col" v-else>
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">Task Details</div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div
          class="w-[572px] h-[26px] px-4 justify-between items-center inline-flex"
        >
          <div
            class="flex-col justify-center items-start text-left gap-1 inline-flex"
          >
            <div
              class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              {{ data.Project.name }}
            </div>
          </div>
          <div class="h-5 justify-end items-center gap-2 flex">
            <img src="/icons/projects/edit.svg" class="w-5 h-5 relative" />
            <img
              src="/icons/projects/delete-task.svg"
              class="w-5 h-5 relative"
            />
          </div>
        </div>
        <br />
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            {{ data.name }}
          </div>
        </div>
        <div class="flex justify-between gap-4">
          <div class="flex flex-col">
            <div class="text-slate-500 text-sm text-left font-normal">
              Created
            </div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.start_date) }}
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-slate-500 text-left text-sm font-normal">
              Due Date
            </div>
            <div class="text-neutral-900 text-left text-sm font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.due_date) }}
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignees
            </div>
            <div class="flex gap-1 mt-1">
              <img
                v-for="(image, index) in data.Users.slice(0, 3)"
                :key="index"
                :src="image.photo"
                class="w-6 h-6 rounded-full"
                :title="image.name + ' ' + image.last_name"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">
            Description
          </div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.description }}
          </div>
        </div>
      </div>
      <div v-if="flagStartTimer" class="mb-5">
        <LoaderSpinner />
      </div>

      <div v-if="data.status !== 'Complete' && !isOther && !flagStartTimer" class="mb-5">
        {{ isOther }}
        <div
          v-if="!isTimerActive"
          class="w-[572px] h-10 px-10 flex-col justify-start items-end gap-2.5 inline-flex"
        >
          <button
            @click="addTimer()"
            class="self-stretch h-10 pl-4 pr-5 py-2.5 rounded-lg shadow border border-slate-500 justify-center items-center gap-2 inline-flex"
          >
            <img src="/icons/nav-bar/play.svg" class="w-5 h-5 relative" />
            <div
              class="text-slate-500 text-base font-medium font-['Inter'] leading-normal"
            >
              Start Time Tracker
            </div>
          </button>
        </div>
        <div
          v-else
          class="w-[492px] h-[66px] p-3 bg-slate-100 rounded-xl border border-slate-200 justify-between items-center inline-flex"
        >
          <div
            class="px-4 py-1.5 bg-slate-50 rounded-md border border-slate-200 justify-center items-center gap-2.5 flex"
          >
            <div
              class="text-neutral-900 text-xl font-medium font-['Public Sans'] leading-[30px]"
            >
              {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
            </div>
          </div>
          <div class="justify-start items-center gap-3.5 flex">
            <button
              @click="startTimer"
              v-if="isPaused"
              class="w-7 h-7 p-0.5 bg-blue_primary rounded-[20px] justify-center items-center gap-2.5 flex"
            >
              <img
                src="/icons/global-timer/play.svg"
                class="w-[18px] h-[18px] relative"
              />
            </button>
            <button
              @click="pauseTimer"
              v-else
              class="w-7 h-7 p-0.5 bg-blue_primary rounded-[20px] justify-center items-center gap-2.5 flex"
            >
              <img
                src="/icons/global-timer/pause.svg"
                class="w-[18px] h-[18px] relative"
              />
            </button>
            <button @click="stopTimer" v-if="elapsedTimeOnlySeconds > 0">
              <img
                src="/icons/global-timer/stop.svg"
                class="w-8 h-8 relative"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <StopTimerModal
      v-if="showModalStop"
      :isOpen="showModalStop"
      @close="showModalStop = false"
    />
  </div>
</template>
  
  <script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { db, auth } from "../../../firebase/firebaseConfig.js";
import { doc, onSnapshot } from "firebase/firestore";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers";
import StopTimerModal from "../../GlobalTimer/StopTimer.vue";

export default {
  components: {
    LoaderSpinner,
    StopTimerModal,
  },
  props: {
    isOpen: Boolean,
    id_task: String,
    isOther: Boolean,
  },
  emits: ["close"],

  setup(props, { emit }) {
    const store = useStore();
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const project_details = computed(() => store.getters.detailsData);
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const currentUser = auth.currentUser;
    const userTimerDocRef = doc(db, "user-timer", currentUser.uid);
    const stopPushed = ref(null);
    const isPaused = ref(false);
    const flagLoading = ref(true);
    const data = ref("");
    const showModalStop = ref(false);
    const flagStartTimer = ref(false);

    function closeModal() {
      emit("close");
    }
    function toggleModalStop() {
      showModalStop.value = !showModalStop.value;
    }
    const startTimer = async () => {
      await store.dispatch("startTimer", {
        startWithTime: elapsedTimeOnlySeconds.value,
      });
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", { fromButton: true });
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        ticket: selectedTakeaway.value.ticket,
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        isGlobal: true,
        fromButton: true,
      };

      await store.dispatch("pauseTimer", data);
      toggleModalStop();
    };

    async function addTimer() {
      flagStartTimer.value = true;
      const data1 = {
        id_user: store.state.user.uid,
        id_project: project_details.value.id_project,
        id_task: data.value.id_task,
      };
      await store.dispatch("startTracker", data1).then(async (tracker) => {
        if (tracker.data.existing == true) {
          const dataToStart = {
            startWithTime: tracker.data.dataValues.seconds_elapsed,
          };
          await store.dispatch("setSelectedTakeaway", {
            project_name: project_details.value.name,
            task_description: data.value.description,
            id_task: data.value.id_task,
            id_project: project_details.value.id_project,
          });
          await store.dispatch("startTimer", dataToStart);
        } else {
          await store.dispatch("setSelectedTakeaway", {
            project_name: project_details.value.name,
            task_description: data.value.description,
            id_task: data.value.id_task,
            id_project: project_details.value.id_project,
          });
          await store.dispatch("startTimer", {});
        }
      });
      flagStartTimer.value = false;
      closeModal();
    }

    onMounted(async () => {
      await store
        .dispatch("fetchTask", { id_task: props.id_task })
        .then((dataFetched) => {
          data.value = dataFetched;
        });
      if (isTimerActive.value == true) {
        isPaused.value = false;
      }
      flagLoading.value = false;
    });

    onSnapshot(userTimerDocRef, async (doc) => {
      console.log(
        "Se produjo un cambio en el documento de Firestore New GlobalTimer.vue"
      );
      if (doc.exists()) {
        const dataDoc = doc.data();
        const isNewPausedState = dataDoc.isTimerPaused;
        isPaused.value = isNewPausedState;

        if (dataDoc.stop) {
          isPaused.value = true;
          await store.dispatch("pauseTimer", { firestoreFlag: true });
          return;
        }
        if (dataDoc.isTimerActive) {
          if (!isNewPausedState) {
            await store.dispatch("startTimer", {
              startWithTime: elapsedTimeOnlySeconds.value,
              ticket: selectedTakeaway.value.ticket,
              firestoreFlag: true,
            });
            await store.dispatch("updateElapsedTime");
          } else {
            await store.dispatch("pauseTimer", { firestoreFlag: true });
          }
        }
      }
    });

    const formatTime = (seconds) => {
      const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;

      return {
        elapsedHours: addLeadingZero(elapsedHours),
        elapsedMinutes: addLeadingZero(elapsedMinutes),
        elapsedSeconds: addLeadingZero(elapsedSeconds),
      };
    };
    return {
      closeModal,
      formatTime,
      startTimer,
      pauseTimer,
      stopTimer,
      elapsedTimeOnlySeconds,
      selectedTakeaway,
      isTimerActive,
      isPaused,
      flagLoading,
      data,
      helpers,
      toggleModalStop,
      showModalStop,
      addTimer,
      flagStartTimer,
    };
  },
};
</script>
  
  <style scoped>
</style>
  