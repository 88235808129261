export default {
    setCurrentProjects (state, payload) {
        state.project.projects = payload
    },
    setTeammates (state, payload) {
        state.project.teammates = payload
    },
    setSelectedProject (state, payload) { 
        state.project.selectedProject = payload
    },
    clearSelectedProject (state) { 
        state.project.selectedProject = []
    },
}
