import api from "../../api";
export default {
  async startTracker({ commit }, data) {
    try {
      const response = await api.post("/new-kambuchi/time-tracker/start-tracker", data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding time",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addExpense action:", error);
      throw error;
    }
  },

 
};
