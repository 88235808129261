<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="w-[399px] h-[270px] pb-6 bg-white rounded-lg flex-col justify-start items-center gap-4 inline-flex"
    >
      <div
        class="px-[33px] py-4 bg-gray-100 flex-col justify-start items-start gap-2.5 flex"
      >
        <div class="w-[333px] h-[26px] relative">
          <div
            class="left-0 top-0 absolute text-slate-800 text-base font-semibold font-['Public Sans'] leading-relaxed"
          >
            Change Status
          </div>
          <button
            @click="closeModal"
            class="absolute top-0 right-0 p-2 text-gray-400 hover:text-gray-800"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="flex-col justify-start items-start gap-10 flex">
        <div
          class="text-slate-500 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          Change your task status
        </div>
        <div class="flex-col justify-start items-start gap-3 flex">
          <div class="justify-start items-start gap-[46px] inline-flex">
            <div class="justify-start items-center gap-2.5 flex">
              <div
                class="w-[21px] h-[21px] rounded-full border border-slate-400"
              ></div>
              <div
                class="text-gray-700 text-base font-normal font-['Public Sans'] leading-relaxed"
              >
                Approved
              </div>
            </div>
            <div class="justify-start items-center gap-2.5 flex">
              <div
                class="w-[21px] h-[21px] rounded-full border border-slate-400"
              ></div>
              <div
                class="text-gray-700 text-base font-normal font-['Public Sans'] leading-relaxed"
              >
                Complete
              </div>
            </div>
          </div>
        </div>
        <div class="w-[333px] h-10 relative">
          <div
            class="w-[333px] h-10 left-0 top-0 absolute bg-sky-600 rounded"
          ></div>
          <div
            class="left-[141px] top-[11px] absolute text-white text-sm font-medium font-['Poppins'] leading-[19px]"
          >
            Update
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

export default ({
  props: {
    isOpen: Boolean,
  },
  emits: ['close'],
  setup(props, { emit }) {
    function closeModal() {
      emit('close');
    }
    return { closeModal };
  },
});
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  