<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div class="w-11/12 bg-white rounded-xl shadow flex flex-col" v-else>
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Timesheet Details
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div class="flex gap-4">
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-sm text-left font-normal">Date</div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{
                helpers.formatDateToDDMMMYYYY(
                  data[0].Timesheet ? data[0].Timesheet.date_generated : ""
                )
              }}
            </div>
          </div>

          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignee
            </div>
            <div class="flex gap-1 mt-1">
              <img
                class="w-6 h-6 rounded-full"
                :src="data[0].Timesheet ? data[0].Timesheet.User.photo : ''"
                :title="
                  data[0].Timesheet
                    ? data[0].Timesheet.User.name +
                      ' ' +
                      data[0].Timesheet.User.last_name
                    : ''
                "
              />
            </div>
          </div>
      
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Total Hours
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers
                  .convertSecondsToDecimalHours(
                    data[0].Timesheet ? data[0].Timesheet.total_time : ""
                  )
                  .toFixed(2)
              }}
              Hrs
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Range Start Date
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers.formatDateToDDMMMYYYY(
                  data[0].Timesheet ? data[0].Timesheet.range_start_date : ""
                )
              }}
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Range End Date
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers.formatDateToDDMMMYYYY(
                  data[0].Timesheet ? data[0].Timesheet.range_end_date : ""
                )
              }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Status</div>
          <div
            v-if="data[0].Timesheet.status == 'Submitted'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Timesheet.status }}
            </div>
          </div>
          <div
            v-if="data[0].Timesheet.status == 'Approved'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Timesheet.status }}
            </div>
          </div>
          <div
            v-if="data[0].Timesheet.status == 'Rejected'"
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Timesheet.status }}
            </div>
          </div>
        </div>

       
        <div class="p-4 flex flex-col gap-4  overflow-y-auto">
          <div
            class="grid gap-4 font-semibold text-slate-700"
            style="grid-template-columns: 10% 20% 30% 15% 15% 10%"
          >
            <div class="text-left">Project Name</div>
            <div class="text-left">Activity</div>
            <div class="text-left">Activity Details</div>
            <div class="text-left">Start Date</div>
            <div class="text-left">Complete Date</div>
            <div class="text-left">Hours Worked</div>
          </div>
          <div v-if="data.length" class="max-h-80 ">
            <div
              v-for="(item, index) in data"
              :key="index"
              class="grid gap-4 border-t border-slate-200 pt-2"
              style="grid-template-columns: 10% 20% 30% 15% 15% 10%"
            >
              <div class="text-left">
                {{ item.Task ? item.Task.Project.name : "" }}
              </div>
              <div class="text-left">{{ item.Task ? item.Task.name : "" }}</div>
              <div class="text-left">
                {{ item.Task ? item.Task.description : "" }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(item.TimeTracker.started_at)
                    : ""
                }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(
                        item.TimeTracker.completed_at
                      )
                    : ""
                }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers
                        .convertSecondsToDecimalHours(
                          item.TimeTracker.seconds_elapsed
                        )
                        .toFixed(2)
                    : ""
                }}
                Hrs
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="px-4 pt-2 pb-6 justify-end items-center gap-3 inline-flex"
        >
          <button
            @click="rejectTimesheet"
            class="px-3.5 py-[9px] bg-red-400 rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Reject Invoice
            </div>
          </button>

          <button
            @click="aproveTimesheet"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Approve Invoice
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers/index.js";

export default {
  components: {
    LoaderSpinner,
  },
  props: {
    isOpen: Boolean,
    id_timesheet: String,
    status: String,
  },
  emits: ["close"],

  setup(props, { emit }) {
    console.log("props.status = ", props.status);
    console.log("props.status = typeof ", typeof props.status);
    const store = useStore();

    const flagLoading = ref(true);
    const data = ref("");
    const user_type = computed(() => store.state.user.type);

    function closeModal() {
      emit("close");
    }

    async function aproveTimesheet() {
      flagLoading.value = true;
      const data = {
        id_timesheet: props.id_timesheet,
        status: "Approved",
      };
      const resp = await store.dispatch("updateTimesheetStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    async function rejectTimesheet() {
      flagLoading.value = true;
      const data = {
        id_timesheet: props.id_timesheet,
        status: "Rejected",
      };
      const resp = await store.dispatch("updateTimesheetStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    onMounted(async () => {
      await store
        .dispatch("fetchTimesheet", { id_timesheet: props.id_timesheet })
        .then((dataFetched) => {
          console.log("🚀 ~ .then ~ dataFetched:", dataFetched);
          data.value = dataFetched;
          console.log("🚀 ~ .then ~ data:", data.value);
        });

      flagLoading.value = false;
    });

    return {
      closeModal,
      user_type,
      flagLoading,
      data,
      helpers,
      aproveTimesheet,
      rejectTimesheet,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  