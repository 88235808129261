export default {
  async showSnackbar({ commit }, message) {
    console.log("🚀 ~ showSnackbar ~ message:", message)
    commit("setSnackbar", { text: message, enabled: true });
  },
  async showLoader ({commit}) {
    console.log("��� ~ where is my showLoader ~")
    commit("setLoader");
  },
  async hideLoader ({commit}) {
    commit("clearLoader");
  },
};
