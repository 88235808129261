import api from "../../api";
export default {
  async fetchResourcesByProject({ commit }, { id_user }) {
    try {
      const response = await api.get("/new-kambuchi/users/get-user", {
        id: id_user,
      });
      commit("setDetails", response.dataValues);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async addTask({ commit }, data) {
    try {
      const response = await api.post("/new-kambuchi/tasks/add-tasks", data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding project",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addProject action:", error);
      throw error;
    }
  },
  async addTasksResources({ commit }, data) {
    try {
      const response = await api.post("/new-kambuchi/tasks/add-users", data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while adding resources",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in addProjectResources action:", error);
      throw error;
    }
  },

  async fetchTask(_, { id_task }) {
    try {
      const response = await api.get("/new-kambuchi/tasks/get-task", {
        id: id_task,
      });
      console.log("🚀 ~ fetchTask ~ response:", response);
      if (response.ok) {
        return response.task;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchResourcesInTask(_, { id_task }) {
    try {
      const response = await api.get("/new-kambuchi/users/in-task", {
        id_task,
      });
      if (response.ok) {
        console.log("la response de fetch resources for task", response);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async editTask({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/tasks/edit-task`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while editing task",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in editTask action:", error);
      throw error;
    }
  },

  async deleteTask({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/tasks/delete-task`, data);

      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while deleting task",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error in deleteTask action:", error);
      throw error;
    }
  },
};
