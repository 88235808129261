<template>
  <div
    class="flex items-center justify-between bg-slate-100 rounded-lg p-2 border border-slate-200"
  >
    <div class="flex items-center space-x-3">
      <img :src="fileIcon" class="w-10 h-10 rounded-full" alt="File icon" />
      <div>
        <div class="text-neutral-900 text-sm font-medium">
          {{ fileName }}
        </div>
      </div>
    </div>
    <div class="flex items-center gap-2">
      <a :href="fileUrl" target="_blank" class="text-blue-500 text-sm" download>
        Download
      </a>
      <button
        v-if="isPreviewable"
        @click="openModal"
        class="text-blue-500 text-sm"
      >
        Preview
      </button>
    </div>

    <!-- Modal -->
    <div
      v-if="isModalOpen"
      class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50"
      @click.self="closeModal"
    >
      <div class="bg-white p-4 rounded-lg max-w-4xl w-full relative">
        <button
          @click="closeModal"
          class="absolute top-4 right-4 p-2 bg-gray-400 rounded-full flex items-center justify-center"
        >
          <svg
            class="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <div class="overflow-auto" :style="{ maxHeight: '80vh' }">
          <img
            v-if="isImage"
            :src="fileUrl"
            class="w-full h-auto"
            alt="File preview"
          />
          <iframe
            v-else-if="isPdf"
            :src="fileUrl"
            class="w-full h-96"
            :style="{ border: 'none' }"
          ></iframe>
          <!-- Add a fallback if needed for other file types -->
          <p v-else class="text-gray-600">Cannot preview this file type.</p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { computed, ref } from "vue";

export default {
  props: {
    fileUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isModalOpen = ref(false);

    const fileIcon = computed(() => {
      return "/icons/files/file-icon.svg";
    });

    const fileName = computed(() => {
      const urlWithoutParams = props.fileUrl.split("?")[0];
      return urlWithoutParams.split("/").pop();
    });

    const isPreviewable = computed(() => {
      const previewableExtensions = ["pdf", "jpg", "jpeg", "png"];
      const extension = fileName.value.split(".").pop().toLowerCase();
      return previewableExtensions.includes(extension);
    });

    const isImage = computed(() => {
      const imageExtensions = ["jpg", "jpeg", "png"];
      const extension = fileName.value.split(".").pop().toLowerCase();
      return imageExtensions.includes(extension);
    });

    const isPdf = computed(() => {
      const pdfExtension = "pdf";
      const extension = fileName.value.split(".").pop().toLowerCase();
      return extension === pdfExtension;
    });

    const openModal = () => {
      isModalOpen.value = true;
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    return {
      fileIcon,
      fileName,
      isPreviewable,
      isImage,
      isPdf,
      isModalOpen,
      openModal,
      closeModal,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  