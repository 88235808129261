<template>
  <d-modal
    :title="modalTitle"
    cancel-button-text="Cancel"
    confirm-button-text="Confirm"
    v-model:value="modalOpen"
    :action="modalAction"
    :selectFlag="true"
    :itemsSelect="statusTakwaways"
    :from="fromModal"
    :commentsFlag="true"
  >
  </d-modal>

  <!-- Contenido para dispositivos de escritorio -->
  <div class="desktop-view">
    <v-card class="card-content" flat>
      <div class="padd-1">
        <v-row class="row1">
          <v-card-title class="text-left"
            ><v-icon color="white">mdi-book-edit-outline</v-icon
            ><b>
              Activity -
              {{ selectedTakeaway.activityDescription }}</b
            >
          </v-card-title>
        </v-row>
      </div>
      <div class="padd-2">
        <v-card-sub-title
          ><b> Project Name:</b>
          {{ selectedTakeaway.projectName }}
        </v-card-sub-title>

        <br />
        <v-card-sub-title
          ><b> Requester Name:</b>
          {{ selectedTakeaway.requesterName }}
        </v-card-sub-title>
        <br />
        <v-card-sub-title
          ><b> Smartsheet Expert:</b>
          {{ selectedTakeaway.SE ? selectedTakeaway.SE.name : "N/A" }}
        </v-card-sub-title>
        <br />
        <v-card-sub-title
          ><b> Status takeaway:</b>
          {{ selectedTakeaway.status }}
        </v-card-sub-title>
      </div>
      <div class="padd-3">
        <v-card-text>
          <div>
            <h2>
              Elapsed Time:
              {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }}h
              {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }}m
              {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}s
            </h2>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <br />
    <VBtn
      @click="startTimer"
      color="rgb(var(--v-theme-secondary))"
      icon="mdi-play"
      v-if="isPaused"
      class="btn-start-pause"
      size="x-large"
    ></VBtn>
    <VBtn
      @click="pauseTimer"
      color="rgb(var(--v-theme-secondary))"
      icon="mdi-pause"
      class="btn-start-pause"
      size="x-large"
      v-else
    ></VBtn>

    <VBtn
      @click="stopTimer"
      color="rgb(var(--v-theme-secondary))"
      icon="mdi-stop"
      class="btn-stop"
      size="x-large"
      v-if="elapsedTimeOnlySeconds > 0"
    ></VBtn>
  </div>

  <!-- Contenido para dispositivos móviles -->
  <div class="mobile-view">
    <v-card class="card-content-mobile" flat>
      <div class="padd-1-mobile">
        <!-- <v-row class="row1-mobile"> -->
        <v-card-text class="text-left"
          ><b>
            Activity -
            {{ selectedTakeaway.activityDescription }}</b
          >
        </v-card-text>
        <!-- </v-row> -->
      </div>
      <div class="padd-2-mobile">
        <v-card-sub-title
          ><b> Project Name:</b>
          {{ selectedTakeaway.projectName }}
        </v-card-sub-title>

        <br />
        <v-card-sub-title
          ><b> Requester Name:</b>
          {{ selectedTakeaway.requesterName }}
        </v-card-sub-title>
        <br />
        <v-card-sub-title
          ><b> Smartsheet Expert:</b>
          {{ selectedTakeaway.SE ? selectedTakeaway.SE.name : "N/A" }}
        </v-card-sub-title>
        <br />
        <v-card-sub-title
          ><b> Status takeaway:</b>
          {{ selectedTakeaway.status }}
        </v-card-sub-title>
      </div>
      <div class="padd-3-mobile">
        <v-card-text class="text-left">
          <div>
            <h2>
              Elapsed Time:
              {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }}h
              {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }}m
              {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}s
            </h2>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <br />
    <VBtn
      @click="startTimer"
      color="rgb(var(--v-theme-secondary))"
      icon="mdi-play"
      v-if="isPaused"
      class="btn-start-pause"
      size="x-large"
    ></VBtn>
    <VBtn
      @click="pauseTimer"
      color="rgb(var(--v-theme-secondary))"
      icon="mdi-pause"
      class="btn-start-pause"
      size="x-large"
      v-else
    ></VBtn>

    <VBtn
      @click="stopTimer"
      color="rgb(var(--v-theme-secondary))"
      icon="mdi-stop"
      class="btn-stop"
      size="x-large"
      v-if="elapsedTimeOnlySeconds > 0"
    ></VBtn>
  </div>
</template>

  
<script>
import { ref, watch, onBeforeUnmount, onMounted, computed } from "vue";
import { useStore } from "vuex";
import DModal from "./DesicionModal.vue";
import { statusTakwaways } from "../const";
import { db, auth } from "../firebase/firebaseConfig.js";
import { doc, onSnapshot } from "firebase/firestore";
export default {
  props: {
    takeaway: Array,
    isFromUrl: String,
    totalSeconds: Number,
  },
  components: {
    DModal,
  },
  setup(props) {
    const store = useStore();
    const timer = ref(null);
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const modalOpen = ref(false);
    const modalTitle = ref(null);
    const modalAction = ref(null);
    const fromModal = ref(null);
    const currentUser = auth.currentUser;
    const userTimerDocRef = doc(db, "user-timer", currentUser.uid);
    const stopPushed = ref(null);
    const isPaused = ref(false);

    const startTimer = async () => {
      await waitForSelectedTakeaway();
      
      if (elapsedTimeOnlySeconds.value === 0) {
        
        if (props.isFromUrl === "false") {
          await store.dispatch("saveTracker", props.takeaway.ticket);
        } else {
          await store.dispatch("saveTracker", props.takeaway.item.ticket);
        }
        
        await store.dispatch("startTimer", {
          ticket: selectedTakeaway.value.ticket,
          startWithTime: false,
        });
      } else {
        
        await store.dispatch("startTimer", {
          ticket: selectedTakeaway.value.ticket,
          startWithTime: elapsedTimeOnlySeconds.value,
        });
      }
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", {fromButton: true});
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        ticket: selectedTakeaway.value.ticket,
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        fromButton: true
      };
      await store.dispatch("pauseTimer", data);

      modalTitle.value = "Please select the status for this activity";
      modalAction.value = "updateTracker";
      fromModal.value = "stop";
      modalOpen.value = true;
    };

    onBeforeUnmount(() => {});

    onMounted(() => {      
      if (isTimerActive.value == false || isTimerActive.value == null) {
        if (props.totalSeconds > 0) {
          
          store.commit("setElapsedTimeOnlySeconds", props.totalSeconds);
          if (props.isFromUrl === "true") {
            startTimer();
          }
        } else {         
          if (props.isFromUrl === "true") {
            startTimer();
          }
        }
      } else {
        startTimer();
      }
    });

    const formatTime = (seconds) => {
      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;
      return { elapsedHours, elapsedMinutes, elapsedSeconds };
    };

    const waitForSelectedTakeaway = () => {
      console.log("Verificando si existe selectedTakeaway");
      if (selectedTakeaway.value && selectedTakeaway.value.ticket) {
        return Promise.resolve();
      }
      return new Promise((resolve) => {
        const unwatch = watch(
          () => selectedTakeaway.value,
          (newValue) => {
            if (newValue && newValue.ticket) {
              unwatch();
              resolve();
            }
          },
          { immediate: true }
        );
      });
    };

    onSnapshot(userTimerDocRef, async (doc) => {
      console.log(
        "Se produjo un cambio en el documento de Firestore TimeTracker.vue"
      );
      if (doc.exists()) {
        const dataDoc = doc.data();
        const isNewPausedState = dataDoc.isTimerPaused;
        isPaused.value = isNewPausedState;

        if (dataDoc.stop) {
          isPaused.value = true;
          await store.dispatch("pauseTimer", { firestoreFlag: true });
          return;
        }
        if (!isNewPausedState) {
          await store.dispatch("startTimer", {
            startWithTime: elapsedTimeOnlySeconds.value,
            ticket: selectedTakeaway.value.ticket,
            firestoreFlag: true,
          });
          await store.dispatch("updateElapsedTime");
        } else {
          await store.dispatch("pauseTimer", { firestoreFlag: true });
        }
      }
    });

    return {
      elapsedTimeOnlySeconds,
      startTimer,
      pauseTimer,
      stopTimer,
      formatTime,
      // trackerRunning,
      modalOpen,
      modalTitle,
      modalAction,
      statusTakwaways,
      fromModal,
      timer,
      selectedTakeaway,
      isPaused,
    };
  },
};
</script>

<style scoped>
.card-content {
  width: fit-content;
  margin: auto;
  min-width: 600px;
}
.padd-1 {
  background-color: rgb(var(--v-theme-accent));
  padding: 1rem 4rem 1rem 4rem;
  align-items: center;
  display: flex;
  border-radius: 0rem 0rem 0.5rem 0.5rem !important;
}
.padd-2 {
  padding: 2rem 4rem 2rem 4rem;
  background-color: rgb(var(--v-theme-secondary));
  text-align: left;
  color: rgb(var(--v-theme-primary));
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}
.padd-3 {
  padding: 0rem 4rem 0rem 4rem;
  color: white !important;
  background-color: rgb(var(--v-theme-primary));
  border-radius: 0.5rem 0.5rem 0rem 0rem !important;
}

.btn-start-pause {
  margin-right: 0.2rem;
}
.btn-stop {
  margin-left: 0.2rem;
}
.row1 {
  align-items: center;
  color: white;
}

.padd-1-mobile {
  background-color: rgb(var(--v-theme-accent));
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem !important;
  color: white;
  white-space: nowrap;
}

.padd-2-mobile {
  padding: 2rem 0.5rem 2rem 0.5rem;
  background-color: rgb(var(--v-theme-secondary));
  text-align: left;
  color: rgb(var(--v-theme-primary));
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}

.padd-3-mobile {
  padding: 0rem 1rem 0rem 1rem;
  color: white !important;
  background-color: rgb(var(--v-theme-primary));
  border-radius: 0.5rem 0.5rem 0rem 0rem !important;
}

.row1-mobile {
  display: flex;
  align-items: center;
  color: white;
}

.row1-mobile > * {
  margin-right: 0.5rem; /* Espacio entre el ícono y el texto */
  white-space: nowrap; /* Evitar que el texto se divida en varias líneas */
}
.card-content-mobile {
  width: 100%; /* Establecer ancho al 100% para ocupar todo el ancho disponible */
  max-width: 100%; /* Establecer un ancho máximo al 100% */
}
.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}
@media (max-width: 767px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }
}
</style>