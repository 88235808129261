<template>
  <div
    class="floating-component w-[251.19px] h-auto p-[14.36px] bg-white rounded-[10.77px] shadow flex-col justify-start items-start gap-[14.36px] inline-flex"
  >
    <!-- Collapsed -->
    <div v-if="!isExpanded">
      <div class="w-[222.48px] justify-between items-center inline-flex pb-4">
        <div>
          <div
            class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-normal overflow-hidden text-ellipsis"
          >
            {{ selectedTakeaway.project_name }}
          </div>
        </div>
        <div class="justify-center flex gap-3">
          <img
            @click="toggleModalTaskDetail()"
            src="/icons/global-timer/open.svg"
            class="w-5 h-5 relative pointer-events-auto cursor-pointer"
          />
          <img
            @click="toggleExpand"
            src="/icons/global-timer/expand.svg"
            class="w-5 h-5 relative pointer-events-auto cursor-pointer"
          />
        </div>
      </div>
      <div class="w-[222.48px] justify-between items-start inline-flex">
        <div
          class="h-[38.36px] px-4.5 py-[7.17px] bg-slate-100 rounded border border-blue-100 justify-center items-center gap-4.5 flex"
        >
          <div
            class="text-neutral-900 text-[15px] font-normal font-['Public Sans'] leading-none"
          >
            {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
            {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
            {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
          </div>
        </div>
        <div class="justify-start items-center gap-[10.77px] flex">
          <button
            @click="startTimer"
            v-if="isPaused"
            class="flex-col justify-start items-center gap-[5.39px] inline-flex"
          >
            <div
              class="w-[31.395px] h-[31.395px] p-[3.585px] bg-blue_primary rounded-3xl justify-center items-center gap-4.5 inline-flex"
            >
              <img
                src="/icons/global-timer/play.svg"
                class="w-4 h-4 relative"
              />
            </div>
          </button>
          <button
            @click="pauseTimer"
            v-else
            class="flex-col justify-start items-center gap-[5.39px] inline-flex"
          >
            <div
              class="w-[31.395px] h-[31.395px] p-[3.585px] bg-blue_primary rounded-3xl justify-center items-center gap-4.5 inline-flex"
            >
              <img
                src="/icons/global-timer/pause.svg"
                class="w-4 h-4 relative"
              />
            </div>
          </button>
          <button
            @click="stopTimer"
            v-if="elapsedTimeOnlySeconds > 0"
            class="flex-col justify-start items-center gap-[5.39px] inline-flex"
          >
            <img
              src="/icons/global-timer/stop.svg"
              class="w-[35.88px] h-[35.88px] relative"
            />
          </button>
        </div>
      </div>
    </div>
    <!-- Expanded -->
    <div
      v-else
      class="floating-component w-[251.19px] h-auto p-[14.36px] bg-white rounded-[10.77px] shadow flex-col justify-start items-start gap-[14.36px] inline-flex pb-4"
      :class="{ expanded: isExpanded }"
    >
      <div class="w-[222.48px] justify-between items-center inline-flex">
        <div>
          <div
            class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-[27px]"
          >
            Time Tracker
          </div>
        </div>
        <div class="flex gap-3">
          <img
            @click="toggleModalTaskDetail()"
            src="/icons/global-timer/open.svg"
            class="w-5 h-5 relative pointer-events-auto cursor-pointer"
          />
          <img
            @click="toggleExpand"
            src="/icons/global-timer/collapse.svg"
            class="w-5 h-5 relative pointer-events-auto cursor-pointer"
          />
        </div>
      </div>
      <div
        class="px-3 pt-1.5 pb-3 bg-white rounded-bl rounded-br flex-col justify-start items-start gap-3 flex"
      >
        <div class="w-54 flex justify-start items-center gap-[75px] pb-8">
          <div class="flex-col flex-1 justify-start items-start inline-flex">
            <div
              class="text-neutral-900 text-[12px] font-bold font-['Public Sans'] leading-normal overflow-hidden text-ellipsis"
            >
              {{ selectedTakeaway.project_name }}
            </div>
          </div>
        </div>

        <div
          class="w-54 justify-start items-center gap-[75px] inline-flex pb-8"
        >
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-[12px] font-normal font-['Public Sans'] leading-[4.5px]"
            >
              Task:
            </div>
            <br />
            <div
              class="text-neutral-900 text-[12px] font-medium font-['Public Sans'] leading-normal overflow-hidden text-ellipsis"
            >
              {{ selectedTakeaway.task_description }}
            </div>
          </div>
        </div>
        <div class="w-[222.48px] justify-between items-start inline-flex">
          <div
            class="w-50 h-[38.355px] px-4.5 py-[7.17px] bg-slate-100 rounded border border-blue-100 justify-center items-center gap-4.5 flex"
          >
            <div
              class="text-neutral-900 text-[15px] font-normal font-['Public Sans'] leading-none"
            >
              {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
              {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
            </div>
          </div>
          <div class="w-50 justify-end items-center gap-[10.77px] flex">
            <button
              @click="startTimer"
              v-if="isPaused"
              class="w-[31.395px] h-[31.395px] p-[3.585px] bg-blue_primary rounded-3xl justify-center items-center gap-4.5 inline-flex"
            >
              <img
                src="/icons/global-timer/play.svg"
                class="w-5 h-5 relative"
              />
            </button>
            <button
              @click="pauseTimer"
              v-else
              class="flex-col justify-start items-center gap-[5.39px] inline-flex"
            >
              <div
                class="w-[31.395px] h-[31.395px] p-[3.585px] bg-blue_primary rounded-3xl justify-center items-center gap-4.5 inline-flex"
              >
                <img
                  src="/icons/global-timer/pause.svg"
                  class="w-4 h-4 relative"
                />
              </div>
            </button>

            <button
              @click="stopTimer"
              v-if="elapsedTimeOnlySeconds > 0"
              class="flex-col justify-start items-center gap-[5.39px] inline-flex"
            >
              <img
                src="/icons/global-timer/stop.svg"
                class="w-[35.88px] h-[35.88px] relative"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <StopTimerModal
      v-if="showModalStop"
      :isOpen="showModalStop"
      @close="showModalStop = false"
    />
    <ModalTaskDetails
      v-if="showModalTaskDetail"
      :id_task="selectedTakeaway.id_task"
      :isOther="false"
      :isOpen="showModalTaskDetail"
      @close="showModalTaskDetail = false"
    />
  </div>
</template>
    
  <script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { db, auth } from "../../firebase/firebaseConfig.js";
import { doc, onSnapshot } from "firebase/firestore";
import StopTimerModal from "../GlobalTimer/StopTimer.vue";
import ModalTaskDetails from "../Projects/Tasks/TaskDetails.vue";

export default {
  components: {
    StopTimerModal,
    ModalTaskDetails,
  },
  setup() {
    const store = useStore();
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const currentUser = auth.currentUser;
    const userTimerDocRef = doc(db, "user-timer", currentUser.uid);
    const isExpanded = ref(true);
    const stopPushed = ref(null);
    const isPaused = ref(false);
    const showModalStop = ref(false);
    const showModalTaskDetail = ref(false);

    function toggleExpand() {
      isExpanded.value = !isExpanded.value;
    }
    function toggleModalStop() {
      showModalStop.value = !showModalStop.value;
    }
    const startTimer = async () => {
      await store.dispatch("startTimer", {
        startWithTime: elapsedTimeOnlySeconds.value,
      });
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", { fromButton: true });
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        isGlobal: true,
        fromButton: true,
      };

      await store.dispatch("pauseTimer", data);
      toggleModalStop();
    };

    function toggleModalTaskDetail() {

      showModalTaskDetail.value = !showModalTaskDetail.value;
    }

    onMounted(() => {
      if (isTimerActive.value == true) {
        isPaused.value = false;
      }
    });

    onSnapshot(userTimerDocRef, async (doc) => {
      console.log(
        "Se produjo un cambio en el documento de Firestore New GlobalTimer.vue"
      );
      if (doc.exists()) {
        const dataDoc = doc.data();
        const isNewPausedState = dataDoc.isTimerPaused;
        isPaused.value = isNewPausedState;

        if (dataDoc.stop) {
          console.log("stop es false NewGlobalTimer.vue");
          isPaused.value = true;
          await store.dispatch("pauseTimer", { firestoreFlag: true });
          return;
        }
        if (dataDoc.isTimerActive) {
          if (!isNewPausedState) {
            console.log("entra aqui? NewGlobalTimer.vue");
            await store.dispatch("startTimer", {
              startWithTime: elapsedTimeOnlySeconds.value,
              firestoreFlag: true,
            });
            await store.dispatch("updateElapsedTime");
          } else {
            await store.dispatch("pauseTimer", { firestoreFlag: true });
          }
        }
      }
    });

    const formatTime = (seconds) => {
      const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;

      return {
        elapsedHours: addLeadingZero(elapsedHours),
        elapsedMinutes: addLeadingZero(elapsedMinutes),
        elapsedSeconds: addLeadingZero(elapsedSeconds),
      };
    };

    return {
      isExpanded,
      toggleExpand,
      formatTime,
      startTimer,
      pauseTimer,
      stopTimer,
      elapsedTimeOnlySeconds,
      selectedTakeaway,
      isTimerActive,
      isPaused,
      toggleModalStop,
      showModalStop,
      toggleModalTaskDetail,
      showModalTaskDetail
    };
  },
};
</script>
    
  <style scoped>
.floating-component {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
.floating-component.expanded {
  height: auto;
}
</style>
  