<template>
  <div
    class="w-full h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Create New Invoice
      </div>
    </div>
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Project Information
      </div>
    </div>
    <div
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Project Name
        </div>
        <input
          type="text"
          placeholder="person"
          class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 justify-start items-start gap-2 inline-flex"
        />
      </div>
    </div>
    <div
      class="self-stretch h-[152px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Task Name
      </div>
      <textarea
        placeholder="Enter description"
        class="self-stretch h-[120px] px-3 py-2 bg-white rounded-lg shadow border border-slate-200 justify-start items-start gap-2 inline-flex"
      />
    </div>
    <div class="w-full h-auto flex-col justify-start items-start gap-1.5 flex">
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Address
        </div>
        <div class="relative w-full">
          <input
            type="text"
            placeholder="address"
            class="self-stretch grow shrink basis-0 px-3 py-2 bg-white rounded-lg border border-slate-200 justify-start items-center gap-2 inline-flex w-full"
          />
          <img
            class="w-5 h-5 absolute right-0 top-0 mt-2 mr-2"
            src="/icons/projects/address.svg"
          />
        </div>
      </div>
    </div>
    <div class="self-stretch justify-start items-start gap-5 inline-flex">
      <div
        class="grow shrink basis-0 h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Rate Type
          </div>

          <input
            type="number"
            placeholder="hourly"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 justify-start items-start gap-2 inline-flex"
          />
        </div>
      </div>
      <div
        class="grow shrink basis-0 h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Rate per
          </div>
          <input
            type="number"
            placeholder="daily"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 justify-start items-start gap-2 inline-flex"
          />
        </div>
      </div>
    </div>
    <div
      class="self-stretch pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
    >
      <div
        class="grow shrink basis-0 h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Start Date
          </div>
          <div class="relative w-full">
            <input
              type="date"
              placeholder="dd/mm/yyyy"
              class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 w-full"
            />
          </div>
        </div>
      </div>
      <div
        class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            End Dte
          </div>
          <div class="relative w-full">
            <input
              type="date"
              placeholder="dd/mm/yyyy"
              class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 w-full"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="self-stretch justify-between items-center inline-flex">
      <div
        class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Client Information
      </div>
      <div
        class="p-1 bg-slate-200 rounded-[20px] justify-start items-start gap-2.5 flex"
      >
        <div class="w-4 h-4 relative"></div>
      </div>
    </div>
    <div class="self-stretch justify-center items-center gap-5 inline-flex">
      <div
        class="w-[552px] h-[74px] flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Client
        </div>
        <div
          class="self-stretch h-[42px] px-3 py-[9px] bg-white rounded-lg border border-slate-200 justify-start items-center gap-2 inline-flex"
        >
          <div
            class="grow shrink basis-0 h-5 justify-start items-center gap-2 flex"
          >
            <div
              class="grow shrink basis-0 h-5 justify-start items-center gap-2 flex"
            >
              <div class="w-6 h-6 flex-col justify-start items-end inline-flex">
                <img
                  class="w-6 h-6 rounded-[100px]"
                  src="https://via.placeholder.com/24x24"
                />
              </div>
              <div
                class="grow shrink basis-0 flex-col justify-center items-start gap-0.5 inline-flex"
              >
                <div
                  class="self-stretch text-slate-600 text-[10px] font-normal font-['Public Sans'] leading-none"
                >
                  Jay Hargudson
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1.5 inline-flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Phone Number
          </div>
          <input
            type="number"
            placeholder="XXXX-XXX-XXX"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 justify-start items-start gap-2 inline-flex"
          />
        </div>
      </div>
    </div>
    <div class="w-[1124px] justify-end items-center gap-3 inline-flex">
      <button
        class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Cancel
        </div>
      </button>
      <button
        class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Invoice
        </div>
      </button>
    </div>
  </div>
</template>