export default {
  setTrackerElapsedTime(state, payload) {
    state.tracker.elapsedTime = payload;
  },
  setIsTimerActive(state) {
    state.tracker.isTimerActive = true;
  },
  clearIsTimerActive(state) {
    state.tracker.isTimerActive = false;
  },
  clearElapsedTime(state) {
    state.tracker.elapsedTime = null;
  },
  setElapsedTimeOnlySeconds(state, payload) {
    state.tracker.elapsedTimeOnlySeconds = payload;
  },
  clearElapsedTimeOnlySeconds(state) {
    state.tracker.elapsedTimeOnlySeconds = null;
  },
  setSelectedTakeaway(state, payload) {
    state.tracker.selectedTakeaway = payload;
  },
  clearSelectedTakeaway(state) {
    state.tracker.selectedTakeaway = {};
  },
  setSelectedTakeawayStatus (state, payload) {
    state.tracker.selectedTakeaway.status = payload;
  }
};
