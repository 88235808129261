<template>
  <div>
    <link
      href="https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Workbench&display=swap"
      rel="stylesheet"
    />
    <div
      v-if="$store.state.appLoaded === true"
      id="app-container"
      class="relative"
    >
      <NavBar
        v-if="shouldShowAppBar"
        class="absolute top-0 left-0 right-0 z-1 w-full"
      />
      <div class="flex flex-grow h-full">
        <SideBar
          v-if="shouldShowAppBar"
          class="w-60 bg-blue_primary text-white z-0 h-full"
        />
        <main
          :class="{ 'with-app-bar': shouldShowAppBar }"
          class="flex-1 p-4 flex-grow h-full"
        >
          <router-view />
        </main>
      </div>
      <SnackBar />
      <NewGlobalTimer v-if="shouldShowGlobalTimer" />
    </div>
    <div v-else>
      <CircularLoader />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import SnackBar from "./components/SnackBar.vue";
import CircularLoader from "./components/CircularLoader.vue";
import SideBar from "./components/Bars/SideBar.vue";
import NavBar from "./components/Bars/NavBar.vue";
import NewGlobalTimer from "./components/GlobalTimer/GlobalTimer.vue";
import { auth } from "./firebase/firebaseConfig.js";

export default {
  components: {
    SnackBar,
    CircularLoader,
    SideBar,
    NavBar,
    NewGlobalTimer,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const trackerRunning = computed(() => store.getters.isTimerActive);

    const excludedRoutes = [
      "/login",
      "/register-v2",
      "/forgot-password",
      "/register-resource",
    ];

    const shouldShowAppBar = computed(() => {
      return !excludedRoutes.includes(route.path) && !!auth.currentUser;
    });

    const shouldShowGlobalTimer = computed(() => {
      return trackerRunning.value && !excludedRoutes.includes(route.path);
    });

    return {
      shouldShowAppBar,
      shouldShowGlobalTimer,
    };
  },
};
</script>

<style>
html,
body,
#app-container {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app-container {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.with-app-bar {
  margin-top: 60px;
}

.flex-grow {
  flex-grow: 1;
}
</style>
