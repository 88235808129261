<!-- <template>
  <v-form v-model="valid" @submit.prevent="submit">
    <div v-if="showLoader">
      <Loader />
    </div>
    <v-container v-else>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            hide-details
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
            label="Password"
            hide-details
            required
            :type="showPassword ? 'text' : 'password'"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn type="submit" block class="mt-2">Login</v-btn>
    </v-container>
  </v-form>
</template> -->
<template>
  <Login1 />
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Login1 from "../components/LoginComponents/LoginOne.vue";
export default {
  components: {
    Login1,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      if (store.state.loggedUser) {
        router.push({ name: "dashboard" });
      }
    });

    return {};
  },
};
</script>
<style module>

</style>
