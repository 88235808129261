export default {
  setAppLoaded(state) {
    state.appLoaded = true;
  },
  setSnackbar(state, { text, enabled }) {
    state.app.snackbar.text = text;
    state.app.snackbar.enabled = enabled;
  },
  clearSnackbar(state) {
    state.app.snackbar.text = "";
    state.app.snackbar.enabled = false;
  },
  setLoader(state) {
    state.app.loader = true;
  },
  clearLoader(state) {
    state.app.loader = false;
  },
  setDrawer(state) {
    state.app.drawer = true;
  },
  clearDrawer(state) {
    state.app.drawer = false;
  },
};
