<template>
  <e-modal v-model:value="modalOpen" :commentsFlag="true" :title="modalTitle" :itemName="itemName" />
  <d-modal
    title="Delete ticket"
    cancel-button-text="Cancel"
    confirm-button-text="Confirm"
    v-model:value="modalDesicionOpen"
    :action="modalDesicionAction"
    :selectFlag="false"
    :itemsSelect="statusTakwaways"
    :from="from"
  >
  </d-modal>

  <div class="desktop-view">
    <div class="table-container">
      <v-card color="secondary" flat>
        <v-card-title class="d-flex align-center pe-2 my-takeaways">
          <v-icon icon="mdi-book-edit"></v-icon> &nbsp; My Takeaways
          <v-spacer></v-spacer>
        </v-card-title>
        <div class="table-responsive">
          <table>
            <colgroup>
              <col style="width: 5%" />
              <col style="width: 8%" />
              <col style="width: 10%" />
              <col style="width: 15%" />
              <col style="width: 15%" />
              <col style="width: 10%" />
              <col style="width: 3%" />
              <col style="width: 8%" />
              <col style="width: 6%" />
              <col style="width: 6%" />
              <col style="width: 4%" />
              <col style="width: 10%" />
            </colgroup>
            <thead class="headers">
              <tr>
                <th>Ticket #</th>
                <th>Status</th>
                <th>Assigned To</th>
                <th>Activity Description</th>
                <th>Project Name</th>
                <th>Requester Name</th>
                <th>Priority</th>
                <th>Created Date</th>
                <th>Start Tracking</th>
                <th>Last Tracking</th>
                <th>Elapsed Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody class="bodies">
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.ticket }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.SE.name }}</td>
                <td>{{ item.activityDescription }}</td>
                <td>{{ item.projectName }}</td>
                <td>{{ item.requesterName }}</td>
                <td>{{ item.priority }}</td>
                <td>{{ formattedDate(item.createdDate) }}</td>
                <td>
                  {{
                    item.tracker.starts
                      ? formatDateTime(item.tracker.starts)
                      : "---"
                  }}
                </td>
                <td>
                  {{
                    item.tracker.lastUpdatedDate
                      ? formatDateTime(item.tracker.lastUpdatedDate)
                      : "---"
                  }}
                </td>
                <td>
                  {{
                    item.tracker.elapsedHours ? item.tracker.elapsedHours : 0
                  }}:
                  {{
                    item.tracker.elapsedMinutes
                      ? item.tracker.elapsedMinutes
                      : 0
                  }}:
                  {{
                    item.tracker.elapsedSeconds
                      ? item.tracker.elapsedSeconds
                      : 0
                  }}
                </td>
                <td style="text-align: center">
                  <v-row align="center" justify="center">
                    <v-btn
                      @click="startTracking(item)"
                      color="primary"
                      icon="mdi-play"
                    ></v-btn>
                    <v-btn
                      @click="editModal(item)"
                      color="primary"
                      icon="mdi-pencil"
                    ></v-btn>
                    <v-btn
                      @click="deleteModal(item)"
                      color="primary"
                      icon="mdi-delete"
                    ></v-btn>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </div>
  </div>
  <div class="mobile-view">
    <div class="row">
      <div class="col-md-6 mb-3" v-for="item in items" :key="item.id">
        <v-card>
          <v-card-title class="my-takeaways text-left">
            <v-icon icon="mdi-book-edit"></v-icon> &nbsp;Ticket Number:
            {{ item.ticket }}
          </v-card-title>
          <v-card-text class="text-left">
            <div>Project Name: {{ item.projectName }}</div>
            <div>Requester Name: {{ item.requesterName }}</div>
            <div>Description: {{ item.activityDescription }}</div>
            <div>Status: {{ item.status }}</div>
            <div>Assigned To: {{ item.SE.name }}</div>
            <div>Priority: {{ item.priority }}</div>
            <div>Created Date: {{ item.createdDate }}</div>
            <!-- Agrega más campos según sea necesario -->
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="startTracking(item)"
              color="primary"
              icon="mdi-play"
            ></v-btn>
            <v-btn
              @click="editModal(item)"
              color="primary"
              icon="mdi-pencil"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref /*onMounted*/ } from "vue";
import { useRouter } from "vue-router";
import EModal from "./EditModal.vue";
import { useStore } from "vuex";
import DModal from "./DesicionModal.vue";

export default {
  props: {
    items: Array,
  },
  components: {
    EModal,
    DModal,
  },
  setup(/*props*/) {
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const modalOpen = ref(false);
    const modalDesicionOpen = ref(false);
    const modalTitle = ref("");
    const modalDesicionAction = ref("");
    const from = ref("");
    const itemName = ref(null);

    const startTracking = async (item) => {
      router.push({ name: "t-t", query: { item: JSON.stringify({ item }) } });
    };

    // onMounted(() => {
    //   console.log("Items in TakeawaysList:", props.items);
    // });

    function formattedDate(date) {
      const dateObject = new Date(date);
      const formattedDate = dateObject.toISOString().split("T")[0];
      return formattedDate;
    }
    function formatDateTime(isoString) {
      const date = new Date(isoString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDateTime;
    }

    const editModal = async (item) => {
      await store.dispatch("getTracker", {
        ticket: item.ticket,
      });
      modalTitle.value = item.ticket
      itemName.value = item.projectName
      modalOpen.value = true;
      
    };

    const deleteModal = async (item) => {    
      modalDesicionAction.value = "deleteTakeaway";  
      from.value = item.ticket
      modalDesicionOpen.value = true;
      
    };
    

    return {
      search,
      startTracking,
      formattedDate,
      formatDateTime,
      modalOpen,
      editModal,
      modalTitle,
      modalDesicionOpen,
      deleteModal,
      from,
      modalDesicionAction,
      itemName
    };
  },
};
</script>

<style scoped>
.fixed-layout {
  table-layout: fixed;
}

.table-container {
  display: flex !important;
  justify-content: center !important;
}

.headers {
  color: white !important;
  background-color: rgb(var(--v-theme-primary)) !important;
  text-align: left;
}

.my-takeaways {
  color: white !important;
  background-color: rgb(var(--v-theme-secondary)) !important;
}

th {
  border-left: 2rem !important;
}

.bodies {
  text-align: left;
  color: rgb(var(--v-theme-primary)) !important;
  background-color: #eeeeee !important;
}

td {
  height: 3rem;
}

.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}

@media (max-width: 767px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }
}
</style>
