<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 "
  >
    <LoaderSpinner v-if="flagLoading" />
    <div class="w-[572px] bg-white rounded-xl shadow flex flex-col" v-else>
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Expense Details
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div
          class="w-[572px] h-[26px] px-4 justify-between items-center inline-flex"
        >
          <div
            class="flex-col justify-center items-start text-left gap-1 inline-flex"
          >
            <div class="text-slate-500 text-left text-sm font-normal">
              Project
            </div>
            <div
              class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              {{ data.Project ? data.Project.name : "" }}
            </div>
          </div>
        </div>
        <br />

        <div class="flex justify-between gap-4">
          <div class="flex flex-col">
            <div class="text-slate-500 text-sm text-left font-normal">Date</div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.date) }}
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignee
            </div>
            <div class="flex gap-1 mt-1">
              <img
                class="w-6 h-6 rounded-full"
                :src="data.User ? data.User.photo : ''"
                :title="
                  data.User ? data.User.name + ' ' + data.User.last_name : ''
                "
              />
            </div>
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Name</div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.name }}
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">
            Comments
          </div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.comments }}
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Type</div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.type }}
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Amount</div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            ${{ data.amount }}
          </div>
        </div>


        

        <div v-if="data.ExpenseFiles" class="max-h-80 overflow-y-auto">
          <div class="text-slate-500 text-left text-sm font-normal">Files</div>
          <FilePreview
            v-for="(file, index) in data.ExpenseFiles"
            :key="index"
            :file-url="file.file_url"
          />
        </div>



        <!-- <div v-if="data.ExpenseFiles" class="max-h-80 overflow-y-auto ">
          <div class="text-slate-500 text-left text-sm font-normal">Files</div>
          <div
            v-for="(file, index) in data.ExpenseFiles"
            :key="index"
            class="flex items-center justify-between bg-slate-100 rounded-lg p-2 border border-slate-200"
          >
            <div class="flex items-center space-x-3">
              <img
                src="/icons/files/file-icon.svg"
                class="w-10 h-10 rounded-full"
                alt="File icon"
              />
              <div>
                <div class="text-neutral-900 text-sm font-medium">
                  {{ file.file_url }}
                </div>
              </div>
            </div> -->
            <!-- <button @click="removeFile(index)" class="text-red-700">✖</button> -->
          <!-- </div> -->
        <!-- </div> -->
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Status</div>
          <div
            v-if="data.status == 'Submitted'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Approved'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Rejected'"
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
        </div>
        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="px-4 pt-2 pb-6 justify-start items-center gap-3 inline-flex"
        >
          <button
            @click="rejectExpense"
            class="px-3.5 py-[9px] bg-red-400 rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Reject Expense
            </div>
          </button>

          <button
            @click="aproveExpense"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Aprove Expense
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers/index.js";
import FilePreview from "./FilePreview.vue"; // Import the new component

export default {
  components: {
    LoaderSpinner,
    FilePreview,
  },
  props: {
    isOpen: Boolean,
    id_expense: String,
    status: String,
  },
  emits: ["close"],

  setup(props, { emit }) {
    console.log("props.status = ", props.status);
    console.log("props.status = typeof ", typeof props.status);
    const store = useStore();

    const flagLoading = ref(true);
    const data = ref("");
    const user_type = computed(() => store.state.user.type);
    console.log("🚀 ~ setup ~ user_type:", user_type.value);
    console.log("🚀 ~ setup ~ user_type typeof:", typeof user_type.value);

    function closeModal() {
      emit("close");
    }

    async function aproveExpense() {
      flagLoading.value = true;
      const data = {
        id_expense: props.id_expense,
        status: "Approved",
      };
      const resp = await store.dispatch("updateExpenseStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    async function rejectExpense() {
      flagLoading.value = true;
      const data = {
        id_expense: props.id_expense,
        status: "Rejected",
      };
      const resp = await store.dispatch("updateExpenseStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    onMounted(async () => {
      await store
        .dispatch("fetchExpense", { id_expense: props.id_expense })
        .then((dataFetched) => {
          data.value = dataFetched;
        });

      flagLoading.value = false;
    });

    return {
      closeModal,
      user_type,
      flagLoading,
      data,
      helpers,
      aproveExpense,
      rejectExpense,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  