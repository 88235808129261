<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div class="w-[572px] bg-white rounded-xl shadow flex flex-col">
      <div
        class="w-full h-auto bg-white rounded-xl shadow flex flex-col justify-start items-start gap-5"
      >
        <div
          class="w-full p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 justify-between items-center inline-flex"
        >
          <div
            class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
          >
            Stop Timer
          </div>
          <button
            @click="closeModal"
            class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
            style="flex-grow: 0 !important"
          >
            <svg
              class="w-4 h-4 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div class="px-4 justify-center items-center gap-2.5 inline-flex">
          <div
            class="w-full text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Please select one of the following while you want to end the time
            tracker
          </div>
        </div>
        <div class="px-4 flex-row justify-start items-start gap-3 flex">
          <div
            @click="setStatus('Complete')"
            :class="{
              'bg-purple-50 border-blue-700': selectedStatus === 'Complete',
              'bg-white border-gray-400': selectedStatus !== 'Complete',
            }"
            class="flex items-center gap-2 p-2 border rounded-lg cursor-pointer"
          >
            <div
              :class="{
                'bg-blue_primary': selectedStatus === 'Complete',
                'bg-white': selectedStatus !== 'Complete',
              }"
              class="w-4 h-4 rounded-full border"
            ></div>
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Complete
            </div>
          </div>
          <div
            @click="setStatus('In Progress')"
            :class="{
              'bg-purple-50 border-blue-700': selectedStatus === 'In Progress',
              'bg-white border-gray-400': selectedStatus !== 'In Progress',
            }"
            class="flex items-center gap-2 p-2 border rounded-lg cursor-pointer"
          >
            <div
              :class="{
                'bg-blue_primary': selectedStatus === 'In Progress',
                'bg-white': selectedStatus !== 'In Progress',
              }"
              class="w-4 h-4 rounded-full border"
            ></div>
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              In Progress
            </div>
          </div>
          <div
            @click="setStatus('Cancelled')"
            :class="{
              'bg-purple-50 border-blue-700': selectedStatus === 'Cancelled',
              'bg-white border-gray-400': selectedStatus !== 'Cancelled',
            }"
            class="flex items-center gap-2 p-2 border rounded-lg cursor-pointer"
          >
            <div
              :class="{
                'bg-blue_primary': selectedStatus === 'Cancelled',
                'bg-white': selectedStatus !== 'Cancelled',
              }"
              class="w-4 h-4 rounded-full border"
            ></div>
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Cancelled
            </div>
          </div>
        </div>
        <div class="w-full px-4 pt-2 pb-6 flex justify-end items-end gap-3">
          <button
            @click="closeModal"
            class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          >
            <div
              class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Cancel
            </div>
          </button>
          <button
            @click="submit"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Stop Timer
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    isOpen: Boolean,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const selectedStatus = ref("");

    function closeModal() {
      emit("close");
    }

    function setStatus(status) {
      selectedStatus.value = status;
    }

    async function submit() {
      if (selectedStatus.value) {
        await store.dispatch("newUpdateTracker", {
          take: selectedStatus.value,
          cleanSeconds: true,
        });
        await store.commit("clearIsTimerActive");
        closeModal();
      } else {
        alert("Please select a status.");
      }
    }

    return { closeModal, selectedStatus, setStatus, submit };
  },
};
</script>

<style scoped>
/* Add necessary styles */
</style>
