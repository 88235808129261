import user from "./user";
import takeaway from "./takeaway";
import app from "./app";
import tracker from "./tracker";
import project from "./project";
import pagination from "./pagination";
import actionDetails from "./action-details";

export default {
  ...user,
  ...takeaway,
  ...app,
  ...tracker,
  ...project,
  ...pagination,
  ...actionDetails,
};
