<template>
  <div class="w-full h-10 px-6 justify-start items-center gap-4 inline-flex">
    <div class="flex flex-grow items-center w-1/2">
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Projects
      </div>
    </div>
    <div class="flex justify-end items-center w-1/2 gap-3">
      <div class="relative">
        <input
          type="text"
          placeholder=" Search projects..."
          v-model="searchQuery"
          @input="fetchFilteredProjects"
          class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          style="pointer-events: none"
        />
      </div>
      <button
        class="px-4 py-[9px] bg-white rounded-lg shadow border border-slate-200 flex items-center gap-2"
      >
        <img src="/icons/projects/export.svg" class="w-5 h-5 relative" />
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Export
        </div>
      </button>
      <button
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow flex items-center gap-2"
        @click="newProject()"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Poppins'] leading-snug"
        >
          Create New Project
        </div>
      </button>
      
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { ref } from "vue";
import debounce from "lodash.debounce";
import { useStore } from "vuex";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const searchQuery = ref("");

    function newProject() {
      router.push({ name: "create-new-project" });
    }
    const fetchProjects = async (query) => {
      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "projects",
          search: query,
        });
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    const debouncedFetchProjects = debounce(fetchProjects, 300);

    const fetchFilteredProjects = () => {
      debouncedFetchProjects(searchQuery.value);
    };
    return {
      newProject,
      fetchFilteredProjects,
      searchQuery
    };
  },
};
</script>
  