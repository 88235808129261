<template>
  <div
    class="w-full h-16 px-6 py-3 bg-white justify-end items-center gap-[710px] inline-flex"
  >
    <div class="w-full justify-end items-center gap-[30px] flex">
      <div
        v-if="isTimerActive"
        class="w-[200px] h-10 pl-4 pr-5 py-2.5 bg-slate-100 rounded-lg shadow border border-blue-700 justify-center items-center gap-2 flex"
      >
        <img
          @click="stopTimer"
          v-if="elapsedTimeOnlySeconds > 0"
          src="/icons/global-timer/stop.svg"
          class="w-5 h-5 relative pointer-events-auto cursor-pointer"
        />
        <img
          @click="startTimer"
          v-if="isPaused"
          src="/icons/nav-bar/play.svg"
          class="w-5 h-5 relative pointer-events-auto cursor-pointer"
        />
        <img
          @click="pauseTimer"
          v-else
          src="/icons/global-timer/pause_grey.svg"
          class="w-5 h-5 relative pointer-events-auto cursor-pointer"
        />

        <div
          class="text-blue-700 text-base font-medium font-['Inter'] leading-normal"
        >
          {{ formatTime(elapsedTimeOnlySeconds).elapsedHours }} :
          {{ formatTime(elapsedTimeOnlySeconds).elapsedMinutes }} :
          {{ formatTime(elapsedTimeOnlySeconds).elapsedSeconds }}
        </div>
      </div>
      <button
        v-else
        @click="toggleModalSTT"
        class="w-[200px] pl-4 pr-5 py-2.5 rounded-lg shadow border border-slate-500 justify-center items-center gap-2 flex"
      >
        <img class="w-9 h-9 relative" src="/icons/nav-bar/play.svg" />
        <div
          class="text-slate-500 text-base font-medium font-['Inter'] leading-normal"
        >
          Time Tracker
        </div>
      </button>
      <div class="justify-start items-center gap-[15px] flex">
        <div class="justify-start items-center gap-4 flex">
          <div
            class="bg-slate-100 rounded-lg justify-start items-start gap-4 flex"
          >
            <div class="justify-start items-center gap-4 flex">
              <button
                class="p-2.5 rounded-lg justify-center items-center gap-2 flex"
              >
                <img
                  class="w-5 h-5 relative"
                  src="/icons/nav-bar/question.svg"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          class="bg-slate-100 rounded-lg justify-start items-start gap-4 flex"
        >
          <div class="justify-start items-center gap-4 flex">
            <button
              class="p-2.5 rounded-lg justify-center items-center gap-2 flex"
            >
              <img
                class="w-5 h-5 relative"
                src="/icons/nav-bar/notification.svg"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="justify-start items-start gap-4 flex">
        <div class="flex-col justify-start items-start inline-flex">
          <img
            class="w-10 h-10 rounded-[200px]"
            :src="photo ? photo : 'https://via.placeholder.com/40x40'"
          />
        </div>
      </div>
    </div>
    <StartTimeTracker
      v-if="showModalStart"
      :isOpen="showModalStart"
      @close="showModalStart = false"
    />
    <StopTimerModal
      v-if="showModalStop"
      :isOpen="showModalStop"
      @close="showModalStop = false"
    />
  </div>
</template>
<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import StartTimeTracker from "../GlobalTimer/StartTimeTracker.vue";
import { db, auth } from "../../firebase/firebaseConfig.js";
import { doc, onSnapshot } from "firebase/firestore";
import StopTimerModal from "../GlobalTimer/StopTimer.vue";

export default {
  components: {
    StartTimeTracker,
    StopTimerModal,
  },
  setup() {
    const store = useStore();
    const elapsedTimeOnlySeconds = computed(
      () => store.getters.elapsedTimeOnlySeconds
    );
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const showModalStart = ref(false);
    const showModalStop = ref(false);
    const currentUser = auth.currentUser;
    const userTimerDocRef = doc(db, "user-timer", currentUser.uid);
    const isPaused = ref(false);
    const stopPushed = ref(null);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const usertype = computed(() => store.state.user.type);
    const photo = computed(() =>
      store.state.user ? store.state.user.photo : ""
    );

    function toggleModalSTT() {
      showModalStart.value = !showModalStart.value;
    }
    function toggleModalStop() {
      showModalStop.value = !showModalStop.value;
    }
    const startTimer = async () => {
      await store.dispatch("startTimer", {
        startWithTime: elapsedTimeOnlySeconds.value,
      });
      await store.dispatch("updateElapsedTime");
    };

    const pauseTimer = async () => {
      await store.dispatch("pauseTimer", { fromButton: true });
    };

    const stopTimer = async () => {
      stopPushed.value = true;
      const previousElapsedTime = elapsedTimeOnlySeconds.value;

      const data = {
        ticket: selectedTakeaway.value.ticket,
        elapsedHours: formatTime(previousElapsedTime).elapsedHours,
        elapsedMinutes: formatTime(previousElapsedTime).elapsedMinutes,
        elapsedSeconds: formatTime(previousElapsedTime).elapsedSeconds,
        isGlobal: true,
        fromButton: true,
      };

      await store.dispatch("pauseTimer", data);

      toggleModalStop();
    };

    const formatTime = (seconds) => {
      const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

      const elapsedHours = Math.floor(seconds / 3600);
      const elapsedMinutes = Math.floor((seconds % 3600) / 60);
      const elapsedSeconds = seconds % 60;

      return {
        elapsedHours: addLeadingZero(elapsedHours),
        elapsedMinutes: addLeadingZero(elapsedMinutes),
        elapsedSeconds: addLeadingZero(elapsedSeconds),
      };
    };

    onMounted(() => {
      if (isTimerActive.value == true) {
        isPaused.value = false;
      }
    });

    onSnapshot(userTimerDocRef, async (doc) => {
      console.log(
        "Se produjo un cambio en el documento de Firestore NavBar.vue"
      );
      if (doc.exists()) {
        const dataDoc = doc.data();
        console.log("🚀 ~ onSnapshot ~ dataDoc: NavBar.vue", dataDoc);
        const isNewPausedState = dataDoc.isTimerPaused;
        isPaused.value = isNewPausedState;

        if (dataDoc.stop) {
          console.log("stop es false NavBar.vue");
          isPaused.value = true;
          await store.dispatch("pauseTimer", { firestoreFlag: true });
          return;
        }
        if (dataDoc.isTimerActive) {
          if (!isNewPausedState) {
            console.log("entra aqui? NavBar.vue");
            await store.dispatch("startTimer", {
              startWithTime: elapsedTimeOnlySeconds.value,
              ticket: selectedTakeaway.value.ticket,
              firestoreFlag: true,
            });
            await store.dispatch("updateElapsedTime");
          } else {
            await store.dispatch("pauseTimer", { firestoreFlag: true });
          }
        }
      }
    });
    return {
      toggleModalSTT,
      showModalStart,
      elapsedTimeOnlySeconds,
      formatTime,
      startTimer,
      pauseTimer,
      stopTimer,
      selectedTakeaway,
      isTimerActive,
      isPaused,
      toggleModalStop,
      showModalStop,
      usertype,
      photo,
    };
  },
};
</script>