<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col max-h-[90vh] overflow-y-auto"
      v-else
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Create New Task
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Task Name
          </div>
          <input
            type="text"
            v-model="task_name"
            placeholder="Title"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !task_name,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                task_name,
            }"
          />
        </div>
      </div>
      <div
        class="self-stretch h-[172px] px-4 justify-start items-start gap-16 inline-flex"
      >
        <div
          class="grow shrink basis-0 h-[172px] flex-col justify-start items-start gap-1.5 inline-flex"
        >
          <div
            class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
          >
            <div
              class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              Description
            </div>
            <textarea
              v-model="description"
              placeholder="Enter description"
              :class="{
                'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !description,
                'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  description,
              }"
            ></textarea>
          </div>
        </div>
      </div>
      <div
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div
          class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1.5 inline-flex"
        >
          <div
            class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
          >
            <div
              class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              Start Date
            </div>
            <div class="relative w-full">
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                v-model="start_date"
                :class="{
                  'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                    !start_date,
                  'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                    start_date,
                }"
              />
            </div>
          </div>
        </div>
        <div
          class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1.5 inline-flex"
        >
          <div
            class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
          >
            <div
              class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              Deadline
            </div>
            <div class="relative w-full">
              <input
                v-model="due_date"
                type="date"
                placeholder="dd/mm/yyyy"
                :class="{
                  'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                    !due_date,
                  'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                    due_date,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div class="flex-1 flex-col justify-start items-start gap-1.5 flex">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Status
          </div>
          <select
            v-model="status"
            :class="{
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
                !status,
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
                status,
            }"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in statusOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="flex-1 flex-col justify-start items-start gap-1.5 flex">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Billable?
          </div>
          <select
            v-model="billable"
            :class="{
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
                !billable,
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
                billable,
            }"
          >
            <option value="" disabled>Select an option</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
      </div>

      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Assign to
      </div>
      <div
        class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
      >
        <button
          @click="selectTab('Employees')"
          :class="
            selectedTab === 'Employees'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Employees'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Employees
          </div>
        </button>
        <button
          @click="selectTab('Contractors')"
          :class="
            selectedTab === 'Contractors'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Contractors'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Contractors
          </div>
        </button>
      </div>
      <div
        class="h-auto w-full px-4 flex-col justify-start items-start gap-5 flex"
      >
        <div
          class="h-auto w-full flex-col justify-start items-start gap-3 flex"
        >
          <div
            class="self-stretch h-auto p-4 rounded-lg border border-zinc-200 flex-col justify-start items-center gap-4 flex"
          >
            <input
              v-model="filter"
              type="text"
              placeholder="Search contributor..."
              class="self-stretch px-3 py-2 bg-slate-50 rounded-lg border border-slate-200 justify-start items-center gap-1 inline-flex"
            />
            <LoaderSpinner v-if="flagLoadingUsers" />
            <div
              v-else
              v-for="(user, index) in filteredUsers"
              :key="index"
              class="self-stretch justify-start items-center gap-4 inline-flex"
            >
              <div
                class="grow shrink basis-0 h-10 justify-start items-center gap-3 flex"
              >
                <div class="flex-col justify-start items-end inline-flex">
                  <img :src="user.photo" class="w-10 h-10 rounded-full" />
                </div>
                <div
                  class="grow shrink basis-0 flex-col justify-center items-start gap-0.5 inline-flex"
                >
                  <div
                    class="self-stretch text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
                  >
                    {{ user.name }} {{ user.last_name }}
                  </div>
                  <div
                    class="self-stretch text-slate-600 text-xs font-normal font-['Public Sans'] leading-[18px]"
                  >
                    {{ user.email }}
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'w-5 h-5 relative border-2 rounded cursor-pointer',
                  checkedUsers.includes(user.id_user)
                    ? 'bg-blue_primary'
                    : 'border-blue-700',
                ]"
                @click="toggleCheckbox(user.id_user)"
              >
                <div
                  v-if="checkedUsers.includes(user.id_user)"
                  class="absolute inset-0 flex items-center justify-center text-white"
                >
                  ✓
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 pt-2 pb-6 justify-end items-center gap-3 inline-flex">
        <button
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          @click="closeModal"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>
        <button
          @click="handleCreateTask('addTaskAndCreate')"
          :class="
            !isFormValid
              ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
              : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              !isFormValid
                ? 'text-white text-sm font-semibold  leading-snug'
                : 'text-white text-sm font-semibold  leading-snug'
            "
          >
            Create and add new
          </div>
        </button>
        <button
          @click="handleCreateTask('addTask')"
          :class="
            !isFormValid
              ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
              : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              !isFormValid
                ? 'text-white text-sm font-semibold  leading-snug'
                : 'text-white text-sm font-semibold  leading-snug'
            "
          >
            Add Task
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import LoaderSpinner from "../../CircularLoader.vue";

export default {
  props: {
    isOpen: Boolean,
    projectId: Number,
  },
  components: { LoaderSpinner },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    // const router = useRouter();

    const filter = ref("");
    const checkedUsers = ref([]);
    const users = ref([]);
    const selectedTab = ref("Employees");
    const flagLoading = ref(false);
    const flagLoadingUsers = ref(false);

    const task_name = ref("");
    const description = ref("");
    const start_date = ref("");
    const due_date = ref("");
    const status = ref("");
    const billable = ref("");

    const selectedType = computed(() => store.getters.selectedType);

    const statusOptions = ref(["Not Started", "In progress", "Complete"]);

    const isFormValid = computed(() => {
      return (
        task_name.value &&
        description.value &&
        start_date.value &&
        due_date.value &&
        status.value &&
        billable.value
      );
    });

    const selectTab = async (tab) => {
      flagLoadingUsers.value = true;
      const resources = await store.dispatch("fetchResourceForAssignTasks", {
        type: tab == "Employees" ? 2 : 3,
        id_project: props.projectId,
      });
      users.value = resources.data;
      selectedTab.value = tab;
      flagLoadingUsers.value = false;
    };

    const filteredUsers = computed(() => {
      const userArray = Object.values(users.value);
      console.log("🚀 ~ filteredUsers ~ userArray:", userArray);
      return userArray
        .filter((user) =>
          user.name.toLowerCase().includes(filter.value.toLowerCase())
        )
        .slice(0, 5);
    });

    async function closeModal() {
      const types =
        selectedType.value == "Active"
          ? ["In progress", "Not Started"]
          : ["Complete"];
      await store.dispatch("fetchTable", {
        page: 1,
        limit: 10,
        type: "tasks",
        searchType: types,
        id_project: props.projectId,
      });
      emit("close");
    }

    function toggleCheckbox(userId) {
      const index = checkedUsers.value.indexOf(userId);
      if (index === -1) {
        checkedUsers.value.push(userId);
      } else {
        checkedUsers.value.splice(index, 1);
      }
      console.log("🚀 ~ toggleCheckbox ~ checkedUsers:", checkedUsers.value);
    }

    function handleCreateTask(where) {
      if (!isFormValid.value) {
        return;
      }

      try {
        flagLoading.value = true;
        const taskData = {
          name: task_name.value,
          id_project: props.projectId,
          start_date: start_date.value,
          due_date: due_date.value,
          status: status.value,
          description: description.value,
          billable: billable.value,
        };

        store.dispatch("addTask", taskData).then(async (newTask) => {
          task_name.value = "";
          start_date.value = "";
          due_date.value = "";
          status.value = "";
          description.value = "";
          billable.value = "";

          if (newTask.ok) {
            await addResources(newTask.data.id_task);
            if (where == "addTask") {
              closeModal();
            } else if (where == "addTaskAndCreate") {
              flagLoading.value = false;
            }
          } else {
            flagLoading.value = false;
          }

          checkedUsers.value = [];
        });
      } catch (error) {
        flagLoading.value = false;
        console.error("Error creating project:", error);
      }
    }

    async function addResources(id_task) {
      flagLoading.value = true;
      const data = {
        resources: checkedUsers.value,
        id_task: id_task,
      };
      const resp = await store.dispatch("addTasksResources", data);
      if (resp.ok) {
        return true;
      }
    }

    onMounted(async () => {
      flagLoading.value = true;
      flagLoadingUsers.value = true;

      const resources = await store.dispatch("fetchResourceForAssignTasks", {
        type: selectedTab.value == "Employees" ? 2 : 3,
        id_project: props.projectId,
      });
      users.value = resources.data;
      flagLoading.value = false;
      flagLoadingUsers.value = false;
    });

    return {
      filter,
      filteredUsers,
      closeModal,
      checkedUsers,
      toggleCheckbox,
      selectTab,
      selectedTab,
      flagLoading,
      flagLoadingUsers,
      task_name,
      description,
      start_date,
      due_date,
      status,
      billable,
      statusOptions,
      isFormValid,
      handleCreateTask,
    };
  },
};
</script>

<style scoped>

</style>
