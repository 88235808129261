<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <CircularLoader v-if="loading"></CircularLoader>
    <v-card v-else>
      <v-card-title class="headline"> Ticket #: {{ title }} </v-card-title>

      <v-form>
        <v-select
          :items="statusTakwaways"
          label="Select status"
          v-model="selectedItem"
        >
        </v-select>
        <v-textarea
          v-if="commentsFlag && showComments"
          label="Comments"
          v-model="comments"
          rows="4"
        ></v-textarea>
        <h1>Edit Elapsed Time</h1>
        <br />
        <br />
        <v-row class="roww">
          <div>
            <v-row>
              <h1>Hours:</h1>
              <div class="inputs">
                <v-text-field v-model="hoursInput" rounded></v-text-field>
              </div>
            </v-row>
          </div>
          <div>
            <v-row>
              <h1>Minutes:</h1>
              <div class="inputs">
                <v-text-field v-model="minutesInput" rounded></v-text-field>
              </div>
            </v-row>
          </div>
          <div>
            <v-row>
              <h1>Seconds:</h1>
              <div class="inputs">
                <v-text-field v-model="secondsInput" rounded></v-text-field>
              </div>
            </v-row>
          </div>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          text
          @click="cancel"
          style="background-color: rgb(var(--v-theme-secondary))"
          >{{ cancelButtonText }}</v-btn
        >
        <v-btn
          color="white"
          text
          @click="confirm"
          style="background-color: rgb(var(--v-theme-primary))"
          >{{ confirmButtonText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from "vue";
import CircularLoader from "./CircularLoader.vue";
import { useStore } from "vuex";
import { statusTakwaways } from "../const";
import { useRouter } from "vue-router";

export default {
  components: {
    CircularLoader,
  },
  props: {
    title: String,
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
    confirmButtonText: {
      type: String,
      default: "Confirm",
    },
    value: Boolean,
    action: String,
    selectFlag: Boolean,
    itemsSelect: Array,
    from: String,
    commentsFlag: Boolean,
    itemName: String
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const dialog = ref(false);
    const loading = ref(false);
    const hoursInput = ref(0);
    const minutesInput = ref(0);
    const secondsInput = ref(0);
    const selectedItem = ref("");
    const comments = ref("");
    const showComments = ref(false);

    watch(
      () => props.value,
      (newValue) => {
        if (newValue === true) {
          if (store.state.tracker?.elapsedTime) {
            hoursInput.value = store.state.tracker.elapsedTime.elapsedHours;
            minutesInput.value = store.state.tracker.elapsedTime.elapsedMinutes;
            secondsInput.value = store.state.tracker.elapsedTime.elapsedSeconds;
          }
        } else {
          hoursInput.value = 0;
          minutesInput.value = 0;
          secondsInput.value = 0;
        }
        dialog.value = newValue;
      }
    );

    watch(selectedItem, (newValue) => {
      if (newValue === "Complete") {
        showComments.value = true;
      } else {
        showComments.value = false;
      }
    });

    const cancel = async () => {
      await store.dispatch("clearElapsedTime");
      dialog.value = false;
      comments.value = "";
      emit("update:value", false);
    };

    const confirm = async () => {
      loading.value = true;
      let objUpdate = {
        elapsedHours: hoursInput.value,
        elapsedMinutes: minutesInput.value,
        elapsedSeconds: secondsInput.value,
        status: selectedItem.value,
        ticket: props.title,
        comments: comments.value,
        projectName: props.itemName
      };
      await store.dispatch("updateTrackerEdit", objUpdate);
      dialog.value = false;
      comments.value = "";
      emit("update:value", false);

      await store.dispatch("getTakewaysAct");
      loading.value = false;
      hoursInput.value = 0;
      minutesInput.value = 0;
      secondsInput.value = 0;
      await router.push({ name: "t-t" });
    };

    return {
      dialog,
      cancel,
      confirm,
      loading,
      hoursInput,
      minutesInput,
      secondsInput,
      statusTakwaways,
      selectedItem,
      comments,
      showComments,
    };
  },
};
</script>

<style scoped>
.inputs {
  width: 4rem;
}
.roww {
  padding-left: 1rem !important;
  display: flex;
  justify-content: space-evenly;
}
</style>
