import { ref } from 'vue';

const openMenuId = ref(null);

const setOpenMenuId = (id) => {
  openMenuId.value = id;
};

const getOpenMenuId = () => {
  return openMenuId.value;
};

export default {
  setOpenMenuId,
  getOpenMenuId,
};
