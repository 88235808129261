<template>
  <div id="backGND" >
    <LoaderSpinner v-if="flagLoading" />
    <div class="table-containerr" v-else>
      <DashboardOverview style="margin-bottom: 50px" />
      <DashboardCharts style="margin-bottom: 50px" />
      <DashboardTable style="margin-bottom: 50px" />
    </div>
  </div>
</template>
      
  <script>
import DashboardOverview from "@/components/Dashboard/DashboardOverview.vue";
import DashboardCharts from "@/components/Dashboard/DashboardCharts.vue";
import DashboardTable from "@/components/Dashboard/DashboardTable.vue";
import LoaderSpinner from "../components/CircularLoader.vue";
import { ref, onMounted, /*onUnmounted*/ } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    DashboardOverview,
    DashboardCharts,
    DashboardTable,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const flagLoading = ref(true);
    onMounted(async () => {
      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "projects",
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting projects:", error);
      }
    });
 
    return { flagLoading };
  },
};
</script>
  
  <style scoped>
.table-container {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

</style>
  