<template>
  <div
    v-if="flagShow"
    class="w-full h-[88px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
  >
    <div
      class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
    >
      <button
        @click="selectType('Active')"
        :class="
          selectedType == 'Active'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Active'
              ? 'text-blue_primary text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Active
        </div>
      </button>
      <button
        @click="selectType('Complete')"
        :class="
          selectedType == 'Complete'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedType == 'Complete'
              ? 'text-emerald-700 text-sm font-semibold leading-snug'
              : 'text-gray-500 text-sm font-normal leading-snug'
          "
        >
          Complete
        </div>
      </button>
    </div>
    <div class="justify-start items-center gap-3 flex">
      <div class="relative">
        <input
          type="text"
          placeholder="Search tasks..."
          v-model="searchQuery"
          @input="fetchFiltered"
          class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          style="pointer-events: none"
        />
      </div>
      <select
        class="w-36 h-12 px-3.5 py-2.5 bg-white rounded-lg shadow border border-slate-200 justify-start items-center gap-2 flex"
      >
        <div
          class="grow shrink basis-0 h-[26px] justify-start items-center gap-2 flex"
        >
          <div
            class="grow shrink basis-0 text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
          >
            <option value="" disabled selected>Assigned</option>
          </div>
        </div>
        <div class="w-4 h-4 relative"></div>
      </select>
      <button
        @click="toggleModal()"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create New Task
        </div>
      </button>
      <button
        v-if="user_type == 1"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow flex items-center gap-2"
        @click="toggleModalAssign()"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Assign Resources
        </div>
      </button>
    </div>
    <CreateNewTask
      v-if="showModalChangeStatus"
      :isOpen="showModalChangeStatus"
      :projectId="id_project"
      @close="showModalChangeStatus = false"
    />
    <AssignResources
      v-if="showAssignResources"
      :isOpen="showAssignResources"
      :projectId="id_project"
      @close="showAssignResources = false"
    />
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import CreateNewTask from "./TaskCreateNewTask.vue";
import AssignResources from "../AssingResources.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  components: {
    CreateNewTask,
    AssignResources,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const id_project = route.params.id;
    const showModalChangeStatus = ref(false);
    const showAssignResources = ref(false);
    const searchQuery = ref("");
    const user_type = computed(() => store.state.user.type);
    const flagShow = ref(false);

    const selectedType = computed(() => store.getters.selectedType);

    function toggleModal() {
      showModalChangeStatus.value = !showModalChangeStatus.value;
    }

    function toggleModalAssign() {
      showAssignResources.value = !showAssignResources.value;
    }

    const selectType = async (type) => {
      const types =
        type == "Active" ? ["In progress", "Not Started"] : ["Complete"];
      await store.commit("setSelectedType", type);
      await store.dispatch("fetchTable", {
        page: 1,
        limit: 10,
        type: "tasks",
        searchType: types,
        id_project: id_project,
      });

      emit("type-selected-task", type);
    };

    const fetchFiltered = helpers.createDebouncedFetch(
      store,
      "tasks",
      selectedType,
      id_project
    );

    onMounted(async () => {
      try {
        flagShow.value = false;
        if (selectedType.value == null) {
          await store.commit("setSelectedType", "Active");
        }
        flagShow.value = true;
      } catch (error) {
        console.error("Error setting type:", error);
      }
    });
    return {
      toggleModal,
      showModalChangeStatus,
      showAssignResources,
      toggleModalAssign,
      id_project,
      selectType,
      flagShow,
      selectedType,
      fetchFiltered,
      searchQuery,
      user_type,
    };
  },
};
</script>