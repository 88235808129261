import api from "../../api";

export default {
  async addResource({ commit }, data) {
    try {
      const response = await api.post("new-kambuchi/users/add-user", data);

      if (response.ok) {
        return response;
      } else {
        if (response.error.includes("phone_number_UNIQUE")) {
          commit("setSnackbar", {
            text: "Phone number is in use, please use another.",
            enabled: true,
          });
          return response;
        } else if (response.error.includes("email_UNIQUE")) {
          commit("setSnackbar", {
            text: "Email  is in use, please use another.",
            enabled: true,
          });
          return response;
        } else {
          commit("setSnackbar", {
            text: "Unexpected error while adding client",
            enabled: true,
          });
          return response;
        }
      }
    } catch (error) {
      console.error("Error in addResource action:", error);
      throw error;
    }
  },

  async addAccountOwner({ commit }, data) {
    try {
      const response = await api.post(
        "new-kambuchi/users/add-account-owner",
        data
      );

      if (response.ok) {
        return response;
      } else {
        if (response.error.includes("phone_number_UNIQUE")) {
          commit("setSnackbar", {
            text: "Phone number is in use, please use another.",
            enabled: true,
          });
          return response;
        } else if (response.error.includes("email_UNIQUE")) {
          commit("setSnackbar", {
            text: "Email  is in use, please use another.",
            enabled: true,
          });
          return response;
        } else {
          commit("setSnackbar", {
            text: "Unexpected error while adding account owner",
            enabled: true,
          });
          return response;
        }
      }
    } catch (error) {
      console.error("Error in addAccountOwner action:", error);
      throw error;
    }
  },

  async fetchResource({ commit }, { id_user }) {
    try {
      const response = await api.get("/new-kambuchi/users/get-user", {
        id: id_user,
      });
      commit("setDetails", response.dataValues);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchResourceInfo(_, { id_user }) {
    try {
      const response = await api.get("/new-kambuchi/users/get-user-role", {
        id: id_user,
      });
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },



  async fetchResourceForAssign(_, { type }) {
    try {
      const response = await api.get("/new-kambuchi/users/assign", {
        searchType: type,
      });
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchResourceForAssignTasks(_, { type, id_project }) {
    try {
      const response = await api.get("/new-kambuchi/users/assign/tasks", {
        searchType: type,
        id_project: id_project,
      });
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchResourcesInProject(_, { id_project }) {
    try {
      const response = await api.get("/new-kambuchi/users/in-project", {
        id_project,
      });
      if (response.ok) {
        console.log("la response de fetch resources for assign", response);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchProjectsForUser(_, { id_user }) {
    try {
      const response = await api.get("/new-kambuchi/users/get-projects", {
        id_user,
      });
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchTasksForUser(_, { id_user, id_project }) {
    try {
      const response = await api.get(
        "/new-kambuchi/users/get-projects/get-tasks",
        {
          id_user,
          id_project,
        }
      );
      if (response.ok) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async editResource({ commit }, data) {
    try {
      const response = await api.put(`/new-kambuchi/users/edit-user`, data);

      if (response.message) {
        return response;
      } else {
        if (response.error.includes("phone_number_UNIQUE")) {
          commit("setSnackbar", {
            text: "Phone number is already in use, please use another.",
            enabled: true,
          });
          return response;
        } else if (response.error.includes("email_UNIQUE")) {
          commit("setSnackbar", {
            text: "Email is already in use, please use another.",
            enabled: true,
          });
          return response;
        } else {
          commit("setSnackbar", {
            text: "Unexpected error while editing resource",
            enabled: true,
          });
          return response;
        }
      }
    } catch (error) {
      console.error("Error in editClient action:", error);
      throw error;
    }
  },
};
