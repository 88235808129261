<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else>
    <div
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >
      <!-- Headers -->
      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- ID Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-between items-center inline-flex"
        >
          <div class="justify-center items-center flex">
            <div
              class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
            ></div>
          </div>
        </div>
        <!-- ID Data -->
        <div
          v-for="(item, index) in table"
          :key="index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <input
            type="checkbox"
            class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
          />
          <div
            class="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.id_invoice }}
          </div>
        </div>
      </div>

      <div class="w-[30%] flex-col justify-start items-start inline-flex">
        <!-- Project Name Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Project Name
            </div>
          </div>
        </div>
        <!-- Project Name Data -->
        <div
          v-for="(item, index) in table"
          :key="'project_name-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            src="/icons/projects/project-name.svg"
            class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px] justify-center items-center flex"
          />
          <div
            class="grow shrink basis-0 text-left text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.Project ? item.Project.name : "" }}
          </div>
        </div>
      </div>

      <div class="w-[30%] flex-col justify-start items-start inline-flex">
        <!-- Assigned To Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Assigned To
            </div>
          </div>
        </div>
        <!-- Assigned To Data -->
        <div
          v-for="(item, index) in table"
          :key="'assignedTo-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            class="w-10 h-10 rounded-[200px]"
            :src="item.User ? item.User.photo : ''"
          />
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
            >
              {{ item.User ? item.User.name + " " + item.User.last_name : "" }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Amount Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Amount
            </div>
          </div>
        </div>
        <!-- Total expense Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            ${{ item.amount }}
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Invoice date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Invoice Date
            </div>
          </div>
        </div>
        <!-- Invoice Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.date) }}
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in table"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <PPagination />
  </div>
</template>
  
  <script>
import PPagination from "../ProjectPagination.vue";
import DropdownMenu from "./InvoicesDropDown.vue";
import LoaderSpinner from "../../CircularLoader.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const table = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);

    return {
      table,
      flagLoading,
      helpers,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles if needed */
</style>
  