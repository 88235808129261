<template>
  <LoaderSpinner v-if="flagLoading" />
  <div class="w-full h-screen relative bg-[#b7cef2] overflow-hidden" v-else>
    <div class="absolute inset-0 flex flex-col items-center justify-start">
      <div
        class="absolute w-[569px] h-[585px] bg-[#1762d4] rounded-full blur-[400px]"
        style="z-index: 1"
      ></div>
      <div
        class="absolute w-[351px] h-[361px] bg-[#1762d4] rounded-full blur-[300px]"
        style="z-index: 2"
      ></div>
      <img
        class="w-[500px] h-[130px] mt-[50px]"
        src="/kamvu/logo2x4.png"
        style="z-index: 3"
      />
      <div
        class="w-[500px] mt-10 bg-white rounded-xl shadow-lg p-10 flex flex-col gap-5"
        style="z-index: 4"
      >
        <div class="text-[#4d586c] text-2xl font-bold">Login</div>
        <div class="flex flex-col gap-5">
          <div class="flex flex-col gap-1.5">
            <label class="text-[#4d586c] text-base font-medium">Email</label>
            <input
              v-model="email"
              type="email"
              placeholder="mail@example.com"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !isEmailValid(email),
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  isEmailValid(email),
              }"
            />
          </div>
          <label class="text-[#4d586c] text-base font-medium">Password</label>
          <div class="relative w-full">
            
            <input
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="********"
              class="w-full px-3 py-2 pr-10 bg-white border border-[#dfe3f0] rounded-lg"
            />
            <img
              @click="toggleShowPassword"
              class="w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
              src="/icons/login-register/eye.svg"
              alt="Eye Icon"
            />
          </div>
          <div class="text-xs text-red-500 mt-2 text-left">
            <ul>
              <li :class="{ 'text-green-500': isPasswordLongEnough }">
                Minimum 6 characters
              </li>
              <li :class="{ 'text-green-500': hasUpperCaseLetter }">
                At least one uppercase letter
              </li>
              <li :class="{ 'text-green-500': hasSpecialCharacter }">
                At least one special character
              </li>
            </ul>
          </div>
          <div class="text-xs text-[#475466]">
            Forgot Password?
            <span class="text-[#1762d4] cursor-pointer" @click="forgotPassword"
              >Reset</span
            >
          </div>
        </div>
        <button
          @click="submit"
          class="h-11 px-6 py-2.5 bg-[#1762d4] rounded-lg text-white font-semibold"
        >
          Login
        </button>
        <button
          @click="goToRegister"
          class="h-11 px-6 py-2.5 bg-[#4d586c] rounded-lg text-white font-semibold"
        >
          Account Owner Register
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed } from "vue";
import LoaderSpinner from "../CircularLoader.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: { LoaderSpinner },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const flagLoading = ref(false);
    const showPassword = ref(false);
    const isPasswordLongEnough = computed(() => password.value.length >= 6);
    const hasUpperCaseLetter = computed(() => /[A-Z]/.test(password.value));
    const hasSpecialCharacter = computed(() =>
      /[!@#$%^&*(),.?":{}|<>]/.test(password.value)
    );

    const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email);

    const submit = async () => {
      const data = {
        email: email.value,
        password: password.value,
      };

      try {
        flagLoading.value = true;
        await store.dispatch("setUserAct", data);
        flagLoading.value = false;
      } catch (error) {
        console.error("Error during login:", error);
      }
    };

    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    function forgotPassword() {
      router.push({ name: "forgot-password" });
    }

    function goToRegister() {
      router.push({ name: "register-owner" });
    }

    return {
      isPasswordLongEnough,
      hasUpperCaseLetter,
      hasSpecialCharacter,
      email,
      password,
      isEmailValid,
      submit,
      flagLoading,
      forgotPassword,
      goToRegister,
      toggleShowPassword,
      showPassword,
    };
  },
};
</script>

<style scoped>
</style>