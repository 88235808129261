<template>
  <div>
    <LoaderSpinner v-if="flagLoading" />
    <div
      v-else
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex "
    >
      <!-- Headers -->
      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- ID Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-between items-center inline-flex"
        >
          <div class="justify-center items-center flex">
            <div
              class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
            ></div>
          </div>
          <!-- <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              ID
            </div>
          </div> -->
        </div>
        <!-- ID Data -->
        <div
          v-for="(item, index) in clientsTable"
          :key="index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <input
            type="checkbox"
            class="w-5 h-5 relative bg-white rounded-md border border-slate-200"
          />
          <!-- <div
            class="grow shrink basis-0 text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.id_client }}
          </div> -->
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Name Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Name
            </div>
          </div>
        </div>
        <!-- Name Data -->
        <div
          v-for="(item, index) in clientsTable"
          :key="'taskName-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            class="w-10 h-10 rounded-[200px]"
            :src="item.photo"
          />
          <div
            class="grow shrink basis-0 text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.name }} {{ item.last_name }}
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Company Name Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Company Name
            </div>
          </div>
        </div>
        <!-- Company Name Data -->
        <div
          v-for="(item, index) in clientsTable"
          :key="'assignedTo-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img
            src="/icons/client-management/company.svg"
            class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px] justify-center items-center flex"
          />
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
            >
              {{ item.company_name }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Email Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm text-left font-medium font-['Public Sans'] leading-snug"
            >
              Email
            </div>
          </div>
        </div>
        <!-- Email Data -->
        <div
          v-for="(item, index) in clientsTable"
          :key="'assignedTo-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
            >
              {{ item.email }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Phone Number Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Phone Number
            </div>
          </div>
        </div>
        <!-- Phone Number Data -->
        <div
          v-for="(item, index) in clientsTable"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal text-left font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatPhoneNumber(item.phone_number) }}
          </div>
        </div>
      </div>

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Address Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Address
            </div>
          </div>
        </div>
        <!-- Address Data -->
        <div
          v-for="(item, index) in clientsTable"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] text-left leading-snug"
          >
            {{ item.street }} {{ item.apartment_unit }} {{ item.city }},
            {{ item.state }} {{ item.zipcode }}
          </div>
        </div>
      </div>

      <!-- -->

      <!-- Actions Column -->
      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <div
          v-for="(item, index) in clientsTable"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>     
    </div>
    <PPagination />
    
  </div>
</template>
  
  <script>
import PPagination from "../Projects/ProjectPagination.vue";
import DropdownMenu from "./ClientManDropDown.vue";
import LoaderSpinner from "../CircularLoader.vue";
import helpers from '../../helpers';
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const clientsTable = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);

   

    return {
      clientsTable,
      flagLoading,
      helpers
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles if needed */
</style>
  