import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
//firebase personal google account
// const firebaseConfig = {
//     apiKey: "AIzaSyBsrxeWilzqpkTltqwLLRisNvQoqvfmXAU",
//     authDomain: "kambuchi-tt-test.firebaseapp.com",
//     projectId: "kambuchi-tt-test",
//     storageBucket: "kambuchi-tt-test.appspot.com",
//     messagingSenderId: "307321830837",
//     appId: "1:307321830837:web:c1d2ef9cafb83b03852dfc",
//     measurementId: "G-191D5HRY8L"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDaDN18QKL69uAnl9wq6sNjQ4DmBm15Kro",
  authDomain: "kambuchi-58cd9.firebaseapp.com",
  projectId: "kambuchi-58cd9",
  storageBucket: "kambuchi-58cd9.appspot.com",
  messagingSenderId: "635620925332",
  appId: "1:635620925332:web:c3e0115f68924f4454b981",
  measurementId: "G-9S3VWKHYM5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db };

export { app, auth };
