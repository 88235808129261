<template>
  <div >
    <div class="table-containerr">
      <CircularLoader v-if="loading"></CircularLoader>
      <TakeawaysList v-if="items && items.length > 0" :items="items" />
    </div>
  </div>
</template>
  
  <script>
import TakeawaysList from "@/components/TakeawayList.vue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import CircularLoader from "../components/CircularLoader.vue";

export default {
  components: {
    TakeawaysList,
    CircularLoader     
  },
  setup() {
    const store = useStore();
    const items = ref([]);
    const loading = ref(false);
   

    onMounted(async () => {
      try {
        loading.value = true;
        await store.dispatch("getTakewaysAct");
        if (
          store.state.takeaway.takeaways != undefined &&
          store.state.takeaway.takeaways != null
        ) {
          items.value = Object.keys(store.state.takeaway.takeaways).map(
            (key) => {
              return store.state.takeaway.takeaways[key];
            }
          );
        } else {
          store.dispatch(
            "showSnackbar",
            "No takeaways were found with your username"
          );
        }

        loading.value = false;
      } catch (error) {
        console.error("Error in getTakewaysAct:", error);
      }
    });

    return {
      items,
      loading,
    };
  },
};
</script>
  <style scoped>
.table-container {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

</style>