import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { db, auth } from "./firebase/firebaseConfig.js";
import { doc, onSnapshot } from "firebase/firestore";
import "./global.css";
import "./index.css";
import VueApexCharts from "vue3-apexcharts";
import "@themesberg/flowbite";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        variables: {},
        colors: {
          primary: "#176B87",
          secondary: "#86B6F6",
          accent: "#B4D4FF",
          success: "#EEF5FF",
          warning: "#BD4F6C",
        },
      },
    },
  },
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(VueApexCharts);

let currentUser;
let userTimerDocRef;
let unsubscribeSnapshot;

const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
  console.log("🚀 ~ unsubscribeAuth ~ user: main js", user);
  if (user) {
    currentUser = auth.currentUser;
    userTimerDocRef = doc(db, "user-timer", currentUser.uid);
    const user = await store.dispatch("fetchResourceInfo", {
      id_user: currentUser.uid,
    });
    console.log("🚀 ~ unsubscribeAuth ~ user:", user)
    store.commit("setUserMut", {
      uid: currentUser.uid,
      email: currentUser.email,
      type: user && !user.error ? user.data.type : "",
      photo: user && !user.error ? user.data.photo : "",
    });
    store.commit("setAppLoaded", true);
    // store.dispatch("getActiveTimer", user.uid);

    if (unsubscribeSnapshot) {
      unsubscribeSnapshot();
    }

    unsubscribeSnapshot = onSnapshot(userTimerDocRef, async (doc) => {
      console.log("Se produjo un cambio en el documento de Firestore main.js");
      if (doc.exists()) {
        const dataDoc = doc.data();

        if (dataDoc.isTimerActive && !store.state.tracker.isTimerActive) {
          store.dispatch("getActiveTimer", currentUser.uid);
        } else if (dataDoc.stop && dataDoc.currentAction === "stop") {
          store.commit("clearIsTimerActive");
        }
      }
    });
  } else {
    console.log("soy else pq no hy user en main js");
    if (unsubscribeSnapshot) {
      unsubscribeSnapshot();
    }
    store.commit("clearUserMut");
    store.commit("setAppLoaded", true);
  }
});

app.mount("#app");

app.config.globalProperties.$destroy = unsubscribeAuth;
