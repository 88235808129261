export default  {
    setUserMut (state, payload) {
        state.user = payload
        state.loggedUser = true
    },
    clearUserMut (state) {
        state.user = null
        state.loggedUser = false
    }
    
}