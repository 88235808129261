import api from "../../api";

export default {
  async getTakewaysAct({ state, commit }) {
    const { uid } = state.user;
    try {
      const response = await api.get("takeaways/takeaways", uid);
      commit("getTakeawayMut", response.takeaways.data);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
  async saveTakeaway(context, data) {
    try {
      await api.post("takeaways/add-takeaway", data);
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
  async deleteTakeaway({ dispatch }, data) {
    try {
      const response = await api.delete("takeaways/delete-takeaway", data);
      dispatch('showSnackbar', response.message)  
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error;
    }
  },
};
