<template>
  <div class="relative" ref="menuContainer">
    <img
      src="/icons/projects/action-dots.svg"
      class="w-5 h-5 pointer-events-auto cursor-pointer"
      @click.stop="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10"
    >
      <ul>
        <li
          @click="handleAction('Open')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Open client
        </li>
        <li
          @click="handleAction('Delete')"
          class="px-4 py-2 cursor-pointer hover:bg-red-400 bg-red-200"
        >
          Delete
        </li>
      </ul>
    </div>
    <ModalDelete
      v-if="showDeletedModal"
      :from="'client'"
      :item="item"
      :isOpen="showDeletedModal"
      @close="showDeletedModal = false"
    />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import menuState from "../../const/taskMenuState";
import ModalDelete from "../Projects/DeleteModal.vue";

export default {
  props: {
    item: Object,
  },
  components: {
    ModalDelete,
  },
  setup(props) {
    const showMenu = ref(false);
    const router = useRouter();
    const menuContainer = ref(null);
    const showDeletedModal = ref(false);

    const toggleMenu = () => {
      const newMenuState = !showMenu.value;
      menuState.setOpenMenuId(newMenuState ? props.item.id_client : null);
    };

    const handleAction = (action) => {
      if (action === "Open") {
        toggleClientDetail();
      } else if (action === "Delete") {
        deleteClient();
      }
      showMenu.value = false;
      menuState.setOpenMenuId(null);
    };

    const handleClickOutside = (event) => {
      if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        showMenu.value = false;
        menuState.setOpenMenuId(null);
      }
    };

    watch(
      () => menuState.getOpenMenuId(),
      (newId) => {
        showMenu.value = newId === props.item.id_client;
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleClientDetail() {
      router.push({
        name: "client-desc",
        params: { id: props.item.id_client },
      });
    }
    function deleteClient() {
      showDeletedModal.value = !showDeletedModal.value;
    }

    return {
      showMenu,
      toggleMenu,
      handleAction,
      menuContainer,
      showDeletedModal,
    };
  },
};
</script>

<style scoped>
/* Add necessary styles if needed */
</style>
