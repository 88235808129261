<template>
  <div class="relative" ref="menuContainer">
    <img
      src="/icons/projects/action-dots.svg"
      class="w-5 h-5 pointer-events-auto cursor-pointer"
      @click.stop="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10"
    >
      <ul>
        <li
          @click="handleAction('View')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          View task detail
        </li>
        <li
          v-if="user_type == 1"
          @click="handleAction('Edit')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Edit task
        </li>
        <li
          @click="handleAction('Change')"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          Change status
        </li>
        <li
          @click="handleAction('Delete')"
          class="px-4 py-2 cursor-pointer hover:bg-red-400 bg-red-200"
        >
          Delete task
        </li>
      </ul>
    </div>
    <ModalChangeStatus
      :isOpen="showModalChangeStatus"
      @close="showModalChangeStatus = false"
    />
    <ModalTaskDetails
      v-if="showModalTaskDetail"
      :id_task="item.id_task"
      :isOther="flagIsNotActiveTimer"
      :isOpen="showModalTaskDetail"
      @close="showModalTaskDetail = false"
    />
    <ModalEditTask
      v-if="showModalEditTask"
      :id_task="item.id_task"
      :projectId="item.id_project"
      :isOpen="showModalEditTask"
      @close="showModalEditTask = false"
    />
    <ModalDelete
      v-if="showDeletedModal"
      :from="'task'"
      :item="item"
      :isOpen="showDeletedModal"
      @close="showDeletedModal = false"
    />
  </div>
</template>
  
  <script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import menuState from "../../../const/taskMenuState";
import ModalChangeStatus from "./TaskEditStatus.vue";
import ModalTaskDetails from "./TaskDetails.vue";
import ModalEditTask from "./TaskEdit.vue";
import ModalDelete from "../DeleteModal.vue";
import { useStore } from "vuex";

export default {
  props: {
    item: Object,
  },
  components: {
    ModalChangeStatus,
    ModalTaskDetails,
    ModalEditTask,
    ModalDelete,
  },
  setup(props) {
    const store = useStore();
    const showMenu = ref(false);
    const showModalChangeStatus = ref(false);
    const showModalTaskDetail = ref(false);
    const showModalEditTask = ref(false);
    const showDeletedModal = ref(false);
    const menuContainer = ref(null);

    const isTimerActive = computed(() => store.getters.isTimerActive);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const user_type = computed(() => store.state.user.type);
    const flagIsNotActiveTimer = ref(false);

    const toggleMenu = () => {
      const newMenuState = !showMenu.value;
      menuState.setOpenMenuId(newMenuState ? props.item.id_task : null);
    };

    const handleAction = (action) => {
      if (action == "Change") {
        toggleModal();
      } else if (action == "View") {
        if (isTimerActive.value && isTimerActive.value.toString() == "true") {
          if (
            props.item.id_task.toString() ==
            selectedTakeaway.value.id_task.toString()
          ) {
            toggleModalTaskDetail();
          } else {
            flagIsNotActiveTimer.value = true;
            toggleModalTaskDetail();
          }
        } else {
          if(user_type.value.toString() == "1"){
            flagIsNotActiveTimer.value = true;
          }
          toggleModalTaskDetail();
        }
      } else if (action == "Edit") {
        toggleModalEditTask();
      } else if (action == "Delete") {
        deleteTask();
      }
      showMenu.value = false;
      menuState.setOpenMenuId(null);
    };

    const handleClickOutside = (event) => {
      if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        showMenu.value = false;
        menuState.setOpenMenuId(null);
      }
    };

    watch(
      () => menuState.getOpenMenuId(),
      (newId) => {
        showMenu.value = newId === props.item.id_task;
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    function toggleModal() {
      showModalChangeStatus.value = !showModalChangeStatus.value;
    }
    function toggleModalTaskDetail() {
      showModalTaskDetail.value = !showModalTaskDetail.value;
    }
    function toggleModalEditTask() {
      showModalEditTask.value = !showModalEditTask.value;
    }
    function deleteTask() {
      showDeletedModal.value = !showDeletedModal.value;
    }

    return {
      showMenu,
      toggleMenu,
      handleAction,
      menuContainer,
      showModalChangeStatus,
      showModalTaskDetail,
      showModalEditTask,
      flagIsNotActiveTimer,
      user_type,
      showDeletedModal,
    };
  },
};
</script>
  
  <style scoped>
</style>
  