export default {
  clients: [],
  pagination: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    loading: false
  },
  selectedTab: null,
  selectedType: null
};
