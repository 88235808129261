<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      v-else
      class="w-[399px] h-[270px] pb-6 bg-white rounded-lg flex-col justify-start items-start gap-4 inline-flex"
    >
      <div
        class="px-[33px] py-4 bg-gray-100 flex-col justify-start items-start gap-2.5 flex"
      >
        <div class="w-[333px] h-[26px] relative">
          <div
            class="left-0 top-0 absolute text-slate-800 text-base font-semibold font-['Public Sans'] leading-relaxed"
          >
            Confirm Delete
          </div>
          <button
            @click="closeModal"
            class="absolute top-0 right-0 p-2 text-gray-400 hover:text-gray-800"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="flex-col justify-start items-start gap-10 flex px-[33px]">
        <div
          class="text-slate-500 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          Are you sure you want to delete this {{ from }}?
        </div>
        <div class="w-full h-10 relative">
          <div
            class="w-full h-10 left-0 top-0 absolute bg-red-600 rounded"
          ></div>
          <div
            class="left-[20px] top-[11px] h-auto absolute text-white text-sm font-medium font-['Poppins'] leading-[19px]"
          >
            {{
              from == "task" || from == "expense"
                ? item.name
                : from == "invoice"
                ? "Amount:" + " $" + item.amount
                : from == "timesheet" || from == "bill"
                ? "Date range: " +
                  helpers.formatDateToDDMMMYYYY(item.range_start_date) +
                  " to " +
                  helpers.formatDateToDDMMMYYYY(item.range_end_date)
                : from == "client" || from == "user"
                ? item.name + " " + item.last_name
                : ""
            }}
          </div>
        </div>
      </div>
      <div class="w-full flex-col justify-end items-end gap-10 flex px-[33px]">
        <div class="flex gap-4 mt-auto justify-end items-end">
          <button @click="closeModal" class="px-4 py-2 bg-gray-300 rounded-lg">
            Cancel
          </button>
          <button
            @click="deleteItem"
            class="px-4 py-2 bg-red-600 text-white rounded-lg"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import LoaderSpinner from "../CircularLoader.vue";
import { ref } from "vue";
import helpers from "../../helpers";

export default {
  props: {
    isOpen: Boolean,
    item: String,
    from: String,
  },
  componentes: { LoaderSpinner },
  emits: ["close", "confirm"],

  setup(props, { emit }) {
    console.log("🚀 ~ setup ~ props:", props);
    const store = useStore();
    const flagLoading = ref(false);

    function closeModal() {
      emit("close");
    }

    async function deleteItem() {
      flagLoading.value = true;
      const setId =
        props.from === "task"
          ? props.item.id_task
          : props.from === "expense"
          ? props.item.id_expense
          : props.from === "invoice"
          ? props.item.id_invoice
          : props.from === "timesheet"
          ? props.item.id_timesheet
          : props.from === "bill"
          ? props.item.id_bill
          : props.from === "client"
          ? props.item.id_client
          : props.from === "user"
          ? props.item.id_user
          : "";
      if (props.from === "task") {
        const response = await store.dispatch("deleteTask", { id: setId });
        if (response.ok) {
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "tasks",
            searchType: ["In progress", "Not Started"],
            id_project: props.item.id_project,
          });
        }

        flagLoading.value = false;
        closeModal();
      } else if (props.from === "expense") {
        const response = await store.dispatch("deleteExpense", { id: setId });
        if (response.ok) {
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "expenses",
            searchType: ["Submitted"],
            id_project: props.item.id_project,
          });

          flagLoading.value = false;
          closeModal();
        }
      } else if (props.from === "invoice") {
        const response = await store.dispatch("deleteInvoice", { id: setId });
        if (response.ok) {
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "invoices",
            searchType: ["Submitted"],
            id_project: props.item.Project.id_project,
          });

          flagLoading.value = false;
          closeModal();
        }
      } else if (props.from === "timesheet") {
        const response = await store.dispatch("deleteTimesheet", { id: setId });
        if (response.ok) {
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "timesheets",
            searchType: ["Submitted"],
          });

          flagLoading.value = false;
          closeModal();
        }
      } else if (props.from === "bill") {
        const response = await store.dispatch("deleteBill", { id: setId });
        if (response.ok) {
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "bill",
            searchType: ["Submitted"],
          });

          flagLoading.value = false;
          closeModal();
        }
      } else if (props.from === "client") {
        const response = await store.dispatch("deleteClient", { id: setId });
        if (response.ok) {
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "clients",
          });

          flagLoading.value = false;
          closeModal();
        }
      }
      else if (props.from === "user") {
        const response = await store.dispatch("deleteUser", { id: setId });
        if (response.ok) {
          await store.dispatch("fetchTable", {
            page: 1,
            limit: 10,
            type: "users",
          });

          flagLoading.value = false;
          closeModal();
        }
      }
      flagLoading.value = false;
    }

    return {
      closeModal,
      deleteItem,
      flagLoading,
      helpers,
    };
  },
};
</script>

<style scoped>
/* Add necessary styles */
</style>
